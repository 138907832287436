export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: GraphQL_Date;
  /** Notification emails scalar */
  NotificationEmailsScalar: GraphQL_NotificationEmailsScalar;
};

export enum AccessTokenError {
  EmailDoesNotExist = 'EmailDoesNotExist',
  EmailNotSent = 'EmailNotSent',
  EmailOrPasswordNotSent = 'EmailOrPasswordNotSent',
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  ErrorOnOrganizationCreation = 'ErrorOnOrganizationCreation',
  GoogleTokenInvalid = 'GoogleTokenInvalid',
  InvalidCharityNumber = 'InvalidCharityNumber',
  InvalidOrganizationName = 'InvalidOrganizationName',
  NoDonorAllowedOnMobileApp = 'NoDonorAllowedOnMobileApp',
  PasswordDoNotMatch = 'PasswordDoNotMatch',
  ResetTokenDoNotMatch = 'ResetTokenDoNotMatch',
  StytchOrganizationIdNotFound = 'StytchOrganizationIdNotFound',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  TwoFactorCodeDoesNotMatch = 'TwoFactorCodeDoesNotMatch',
  TwoFactorCodeExpired = 'TwoFactorCodeExpired',
  UnauthorizedToken = 'UnauthorizedToken',
  UrlInvalid = 'UrlInvalid',
  UserAlreadyExistsWithOrganization = 'UserAlreadyExistsWithOrganization',
  UserNotFoundWithOrganization = 'UserNotFoundWithOrganization'
}

export type AccessTokenObject = {
  __typename?: 'AccessTokenObject';
  accessToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AccessTokenError>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  shouldAuthenticateBySms?: Maybe<Scalars['Boolean']>;
  shouldAuthenticateWithTwoFactor?: Maybe<Scalars['Boolean']>;
  shouldRedirectToMFAConfig?: Maybe<Scalars['Boolean']>;
  stytchVerdict?: Maybe<Scalars['String']>;
};

export type AccessTokenObject3RLbgMeK = {
  __typename?: 'AccessTokenObject3RLbgMeK';
  error?: Maybe<AccessTokenObject3RLbgMeKErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject3RLbgMeKErrorObject = {
  __typename?: 'AccessTokenObject3RLbgMeKErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObject6iTx7R8d = {
  __typename?: 'AccessTokenObject6iTx7R8d';
  error?: Maybe<AccessTokenObject6iTx7R8dErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject6iTx7R8dErrorObject = {
  __typename?: 'AccessTokenObject6iTx7R8dErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectFtwN8AOf = {
  __typename?: 'AccessTokenObjectFtwN8AOf';
  error?: Maybe<AccessTokenObjectFtwN8AOfErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectFtwN8AOfErrorObject = {
  __typename?: 'AccessTokenObjectFtwN8AOfErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectIHxJUctK = {
  __typename?: 'AccessTokenObjectIHxJUctK';
  error?: Maybe<AccessTokenObjectIHxJUctKErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectIHxJUctKErrorObject = {
  __typename?: 'AccessTokenObjectIHxJUctKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectLHnSTslJ = {
  __typename?: 'AccessTokenObjectLHnSTslJ';
  error?: Maybe<AccessTokenObjectLHnSTslJErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectLHnSTslJErrorObject = {
  __typename?: 'AccessTokenObjectLHnSTslJErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectWxLidYsF = {
  __typename?: 'AccessTokenObjectWxLidYsF';
  error?: Maybe<AccessTokenObjectWxLidYsFErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectWxLidYsFErrorObject = {
  __typename?: 'AccessTokenObjectWxLidYsFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectuqYta8gA = {
  __typename?: 'AccessTokenObjectuqYTA8gA';
  error?: Maybe<AccessTokenObjectuqYta8gAErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectuqYta8gAErrorObject = {
  __typename?: 'AccessTokenObjectuqYTA8gAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenResponseObject = {
  __typename?: 'AccessTokenResponseObject';
  error?: Maybe<AccessTokenResponseObjectErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenResponseObjectErrorObject = {
  __typename?: 'AccessTokenResponseObjectErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccountBalancesObject = {
  __typename?: 'AccountBalancesObject';
  available?: Maybe<Array<BalanceObject>>;
  issuing?: Maybe<AccountBalancesObject>;
  pending?: Maybe<Array<BalanceObject>>;
};

export type AccountBalancesObjectS543ofVy = {
  __typename?: 'AccountBalancesObjectS543ofVY';
  error?: Maybe<AccountBalancesObjectS543ofVyErrorObject>;
  object?: Maybe<AccountBalancesObject>;
};

export type AccountBalancesObjectS543ofVyErrorObject = {
  __typename?: 'AccountBalancesObjectS543ofVYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ActivateDonationFormInput = {
  donationFormId: Scalars['String'];
  isOnboarding: Scalars['Boolean'];
};

export type ActivateTicketingInput = {
  isOnboarding: Scalars['Boolean'];
  ticketingId: Scalars['String'];
};

export type AggregateRateFieldsObject = {
  __typename?: 'AggregateRateFieldsObject';
  commandId: Scalars['String'];
  rateFields: Array<RateFieldObjectWithCount>;
};

export type AggregateRateFieldsObjectbxMhWVvt = {
  __typename?: 'AggregateRateFieldsObjectbxMhWVvt';
  error?: Maybe<AggregateRateFieldsObjectbxMhWVvtErrorObject>;
  items?: Maybe<Array<AggregateRateFieldsObject>>;
};

export type AggregateRateFieldsObjectbxMhWVvtErrorObject = {
  __typename?: 'AggregateRateFieldsObjectbxMhWVvtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AmplitudeVariantValue {
  A = 'A',
  B = 'B',
  C = 'C',
  CONTROL = 'CONTROL',
  OFF = 'OFF'
}

export type AnswerInput = {
  answer?: InputMaybe<Scalars['String']>;
  choiceIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  rateId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
};

export type AnswerObject = {
  __typename?: 'AnswerObject';
  answer?: Maybe<Scalars['String']>;
  choiceIds?: Maybe<Array<Scalars['String']>>;
  choices?: Maybe<Array<ChoiceObject>>;
  commandId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  productTicketId?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionObject>;
  questionId: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type AnswerObjectFpVnvWiL = {
  __typename?: 'AnswerObjectFpVNVWiL';
  error?: Maybe<AnswerObjectFpVnvWiLErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectFpVnvWiLErrorObject = {
  __typename?: 'AnswerObjectFpVNVWiLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectJl6nJPts = {
  __typename?: 'AnswerObjectJL6nJPts';
  error?: Maybe<AnswerObjectJl6nJPtsErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectJl6nJPtsErrorObject = {
  __typename?: 'AnswerObjectJL6nJPtsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectlTl3h849 = {
  __typename?: 'AnswerObjectlTL3h849';
  error?: Maybe<AnswerObjectlTl3h849ErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectlTl3h849ErrorObject = {
  __typename?: 'AnswerObjectlTL3h849ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseListObject = {
  __typename?: 'AnswerResponseListObject';
  error?: Maybe<AnswerResponseListObjectErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerResponseListObjectErrorObject = {
  __typename?: 'AnswerResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseObject = {
  __typename?: 'AnswerResponseObject';
  error?: Maybe<AnswerResponseObjectErrorObject>;
  object?: Maybe<AnswerObject>;
};

export type AnswerResponseObjectErrorObject = {
  __typename?: 'AnswerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ApplicationInitialStatusObject = {
  __typename?: 'ApplicationInitialStatusObject';
  firstForm?: Maybe<FirstFormObject>;
  hasActiveDonationForm: Scalars['Boolean'];
  hasActiveTicketing: Scalars['Boolean'];
  hasArchivedDonationForm: Scalars['Boolean'];
  hasArchivedTicketing: Scalars['Boolean'];
  hasDonationForm: Scalars['Boolean'];
  hasTicketing: Scalars['Boolean'];
  latestForm?: Maybe<FrontendFormObject>;
};

export type ApplicationInitialStatusResponseObject = {
  __typename?: 'ApplicationInitialStatusResponseObject';
  error?: Maybe<ApplicationInitialStatusResponseObjectErrorObject>;
  object?: Maybe<ApplicationInitialStatusObject>;
};

export type ApplicationInitialStatusResponseObjectErrorObject = {
  __typename?: 'ApplicationInitialStatusResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AvailablePaymentCurrency {
  Cad = 'Cad',
  Usd = 'Usd'
}

export type BalanceObject = {
  __typename?: 'BalanceObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type BalanceTransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum BalanceTransactionType {
  issuing_authorization_hold = 'issuing_authorization_hold',
  issuing_dispute = 'issuing_dispute',
  issuing_transaction = 'issuing_transaction',
  payment = 'payment',
  payment_refund = 'payment_refund',
  payout = 'payout',
  pending_topup = 'pending_topup',
  scheduled_payout = 'scheduled_payout',
  topup = 'topup'
}

export enum BankAccountStatus {
  Errored = 'Errored',
  Verified = 'Verified'
}

export type BidRateObject = {
  __typename?: 'BidRateObject';
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type BidRateObjectcds6iqEp = {
  __typename?: 'BidRateObjectcds6iqEp';
  error?: Maybe<BidRateObjectcds6iqEpErrorObject>;
  items?: Maybe<Array<BidRateObject>>;
};

export type BidRateObjectcds6iqEpErrorObject = {
  __typename?: 'BidRateObjectcds6iqEpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean00gymykM = {
  __typename?: 'Boolean00gymykM';
  error?: Maybe<Boolean00gymykMErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean00gymykMErrorObject = {
  __typename?: 'Boolean00gymykMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean1TqHKyWm = {
  __typename?: 'Boolean1TqHKyWm';
  error?: Maybe<Boolean1TqHKyWmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean1TqHKyWmErrorObject = {
  __typename?: 'Boolean1TqHKyWmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2A9hIodo = {
  __typename?: 'Boolean2A9hIodo';
  error?: Maybe<Boolean2A9hIodoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2A9hIodoErrorObject = {
  __typename?: 'Boolean2A9hIodoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7T3CeE3m = {
  __typename?: 'Boolean7T3CeE3m';
  error?: Maybe<Boolean7T3CeE3mErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7T3CeE3mErrorObject = {
  __typename?: 'Boolean7T3CeE3mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean8Q7uvOvP = {
  __typename?: 'Boolean8Q7uvOvP';
  error?: Maybe<Boolean8Q7uvOvPErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean8Q7uvOvPErrorObject = {
  __typename?: 'Boolean8Q7uvOvPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean9gk3Brhx = {
  __typename?: 'Boolean9gk3Brhx';
  error?: Maybe<Boolean9gk3BrhxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean9gk3BrhxErrorObject = {
  __typename?: 'Boolean9gk3BrhxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean683gzNyv = {
  __typename?: 'Boolean683gzNYV';
  error?: Maybe<Boolean683gzNyvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean683gzNyvErrorObject = {
  __typename?: 'Boolean683gzNYVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanByLwu2oi = {
  __typename?: 'BooleanBYLwu2oi';
  error?: Maybe<BooleanByLwu2oiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanByLwu2oiErrorObject = {
  __typename?: 'BooleanBYLwu2oiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanCn0Ul7Sa = {
  __typename?: 'BooleanCn0UL7SA';
  error?: Maybe<BooleanCn0Ul7SaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanCn0Ul7SaErrorObject = {
  __typename?: 'BooleanCn0UL7SAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanCq7JviqG = {
  __typename?: 'BooleanCq7JviqG';
  error?: Maybe<BooleanCq7JviqGErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanCq7JviqGErrorObject = {
  __typename?: 'BooleanCq7JviqGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanEntuKoon = {
  __typename?: 'BooleanEntuKoon';
  error?: Maybe<BooleanEntuKoonErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanEntuKoonErrorObject = {
  __typename?: 'BooleanEntuKoonErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanEz5saxNi = {
  __typename?: 'BooleanEz5saxNi';
  error?: Maybe<BooleanEz5saxNiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanEz5saxNiErrorObject = {
  __typename?: 'BooleanEz5saxNiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanF1sEl1E3 = {
  __typename?: 'BooleanF1sEL1E3';
  error?: Maybe<BooleanF1sEl1E3ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanF1sEl1E3ErrorObject = {
  __typename?: 'BooleanF1sEL1E3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanF8d9NlEc = {
  __typename?: 'BooleanF8d9NlEC';
  error?: Maybe<BooleanF8d9NlEcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanF8d9NlEcErrorObject = {
  __typename?: 'BooleanF8d9NlECErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanHyNnQAfW = {
  __typename?: 'BooleanHYNnQAfW';
  error?: Maybe<BooleanHyNnQAfWErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanHyNnQAfWErrorObject = {
  __typename?: 'BooleanHYNnQAfWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanITefAan0 = {
  __typename?: 'BooleanITefAAN0';
  error?: Maybe<BooleanITefAan0ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanITefAan0ErrorObject = {
  __typename?: 'BooleanITefAAN0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanIdxljhBi = {
  __typename?: 'BooleanIdxljhBI';
  error?: Maybe<BooleanIdxljhBiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanIdxljhBiErrorObject = {
  __typename?: 'BooleanIdxljhBIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanIpPgKEi5 = {
  __typename?: 'BooleanIpPgKEi5';
  error?: Maybe<BooleanIpPgKEi5ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanIpPgKEi5ErrorObject = {
  __typename?: 'BooleanIpPgKEi5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanJanf8ulG = {
  __typename?: 'BooleanJANF8ulG';
  error?: Maybe<BooleanJanf8ulGErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanJanf8ulGErrorObject = {
  __typename?: 'BooleanJANF8ulGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanJSiEiegg = {
  __typename?: 'BooleanJSiEIEGG';
  error?: Maybe<BooleanJSiEieggErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanJSiEieggErrorObject = {
  __typename?: 'BooleanJSiEIEGGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanK7Cf1Jja = {
  __typename?: 'BooleanK7CF1JJA';
  error?: Maybe<BooleanK7Cf1JjaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanK7Cf1JjaErrorObject = {
  __typename?: 'BooleanK7CF1JJAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanLPthXe5V = {
  __typename?: 'BooleanLPthXE5V';
  error?: Maybe<BooleanLPthXe5VErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanLPthXe5VErrorObject = {
  __typename?: 'BooleanLPthXE5VErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanN9WqejMe = {
  __typename?: 'BooleanN9WQEJMe';
  error?: Maybe<BooleanN9WqejMeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanN9WqejMeErrorObject = {
  __typename?: 'BooleanN9WQEJMeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanQeUj9seU = {
  __typename?: 'BooleanQeUj9seU';
  error?: Maybe<BooleanQeUj9seUErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanQeUj9seUErrorObject = {
  __typename?: 'BooleanQeUj9seUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanRbsdGwy7 = {
  __typename?: 'BooleanRBSDGwy7';
  error?: Maybe<BooleanRbsdGwy7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanRbsdGwy7ErrorObject = {
  __typename?: 'BooleanRBSDGwy7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResponseObject = {
  __typename?: 'BooleanResponseObject';
  error?: Maybe<BooleanResponseObjectErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponseObjectErrorObject = {
  __typename?: 'BooleanResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanT5jvAixT = {
  __typename?: 'BooleanT5jvAixT';
  error?: Maybe<BooleanT5jvAixTErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanT5jvAixTErrorObject = {
  __typename?: 'BooleanT5jvAixTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanTd3RcY9p = {
  __typename?: 'BooleanTD3RcY9p';
  error?: Maybe<BooleanTd3RcY9pErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanTd3RcY9pErrorObject = {
  __typename?: 'BooleanTD3RcY9pErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanUnUMtdgo = {
  __typename?: 'BooleanUnUMtdgo';
  error?: Maybe<BooleanUnUMtdgoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanUnUMtdgoErrorObject = {
  __typename?: 'BooleanUnUMtdgoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVIqrMBmb = {
  __typename?: 'BooleanVIqrMBmb';
  error?: Maybe<BooleanVIqrMBmbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVIqrMBmbErrorObject = {
  __typename?: 'BooleanVIqrMBmbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanVUe9TqDz = {
  __typename?: 'BooleanVUe9TQDz';
  error?: Maybe<BooleanVUe9TqDzErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanVUe9TqDzErrorObject = {
  __typename?: 'BooleanVUe9TQDzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWuhFugIm = {
  __typename?: 'BooleanWUHFugIM';
  error?: Maybe<BooleanWuhFugImErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWuhFugImErrorObject = {
  __typename?: 'BooleanWUHFugIMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanX5FbSsRy = {
  __typename?: 'BooleanX5FbSsRy';
  error?: Maybe<BooleanX5FbSsRyErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanX5FbSsRyErrorObject = {
  __typename?: 'BooleanX5FbSsRyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXNzB24Qc = {
  __typename?: 'BooleanXNzB24Qc';
  error?: Maybe<BooleanXNzB24QcErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXNzB24QcErrorObject = {
  __typename?: 'BooleanXNzB24QcErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanY2gchYJe = {
  __typename?: 'BooleanY2gchYJe';
  error?: Maybe<BooleanY2gchYJeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanY2gchYJeErrorObject = {
  __typename?: 'BooleanY2gchYJeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZ0Dur8qv = {
  __typename?: 'BooleanZ0Dur8qv';
  error?: Maybe<BooleanZ0Dur8qvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZ0Dur8qvErrorObject = {
  __typename?: 'BooleanZ0Dur8qvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanazSplgkC = {
  __typename?: 'BooleanazSplgkC';
  error?: Maybe<BooleanazSplgkCErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanazSplgkCErrorObject = {
  __typename?: 'BooleanazSplgkCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanb2p8zEeg = {
  __typename?: 'Booleanb2p8zEEG';
  error?: Maybe<Booleanb2p8zEegErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanb2p8zEegErrorObject = {
  __typename?: 'Booleanb2p8zEEGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanbHBaKpgj = {
  __typename?: 'BooleanbHBaKpgj';
  error?: Maybe<BooleanbHBaKpgjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanbHBaKpgjErrorObject = {
  __typename?: 'BooleanbHBaKpgjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleancS2v2Uzb = {
  __typename?: 'BooleancS2v2UZB';
  error?: Maybe<BooleancS2v2UzbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleancS2v2UzbErrorObject = {
  __typename?: 'BooleancS2v2UZBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleaneH1Ubzyn = {
  __typename?: 'BooleaneH1Ubzyn';
  error?: Maybe<BooleaneH1UbzynErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleaneH1UbzynErrorObject = {
  __typename?: 'BooleaneH1UbzynErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleaneme5agGx = {
  __typename?: 'Booleaneme5agGX';
  error?: Maybe<Booleaneme5agGxErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleaneme5agGxErrorObject = {
  __typename?: 'Booleaneme5agGXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanfbGeQt0P = {
  __typename?: 'BooleanfbGeQt0P';
  error?: Maybe<BooleanfbGeQt0PErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanfbGeQt0PErrorObject = {
  __typename?: 'BooleanfbGeQt0PErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleang5fHfuys = {
  __typename?: 'Booleang5fHFUYS';
  error?: Maybe<Booleang5fHfuysErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleang5fHfuysErrorObject = {
  __typename?: 'Booleang5fHFUYSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleangbIfPpOk = {
  __typename?: 'BooleangbIfPpOK';
  error?: Maybe<BooleangbIfPpOkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleangbIfPpOkErrorObject = {
  __typename?: 'BooleangbIfPpOKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanhNipxHrM = {
  __typename?: 'BooleanhNIPXHrM';
  error?: Maybe<BooleanhNipxHrMErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanhNipxHrMErrorObject = {
  __typename?: 'BooleanhNIPXHrMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanieLmrpgl = {
  __typename?: 'BooleanieLMRPGL';
  error?: Maybe<BooleanieLmrpglErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanieLmrpglErrorObject = {
  __typename?: 'BooleanieLMRPGLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleaniwYvw2kF = {
  __typename?: 'BooleaniwYvw2kF';
  error?: Maybe<BooleaniwYvw2kFErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleaniwYvw2kFErrorObject = {
  __typename?: 'BooleaniwYvw2kFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanjxm6yt31 = {
  __typename?: 'Booleanjxm6yt31';
  error?: Maybe<Booleanjxm6yt31ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanjxm6yt31ErrorObject = {
  __typename?: 'Booleanjxm6yt31ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanlRh8wcbp = {
  __typename?: 'BooleanlRH8wcbp';
  error?: Maybe<BooleanlRh8wcbpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanlRh8wcbpErrorObject = {
  __typename?: 'BooleanlRH8wcbpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanm9VuYbJo = {
  __typename?: 'Booleanm9VuYBJo';
  error?: Maybe<Booleanm9VuYbJoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanm9VuYbJoErrorObject = {
  __typename?: 'Booleanm9VuYBJoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanmtlJumqG = {
  __typename?: 'BooleanmtlJumqG';
  error?: Maybe<BooleanmtlJumqGErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanmtlJumqGErrorObject = {
  __typename?: 'BooleanmtlJumqGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannjE8QqLj = {
  __typename?: 'BooleannjE8QqLj';
  error?: Maybe<BooleannjE8QqLjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannjE8QqLjErrorObject = {
  __typename?: 'BooleannjE8QqLjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanqNph0bMw = {
  __typename?: 'BooleanqNPH0bMw';
  error?: Maybe<BooleanqNph0bMwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanqNph0bMwErrorObject = {
  __typename?: 'BooleanqNPH0bMwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanrFloHhz2 = {
  __typename?: 'BooleanrFloHHZ2';
  error?: Maybe<BooleanrFloHhz2ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanrFloHhz2ErrorObject = {
  __typename?: 'BooleanrFloHHZ2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanrNHkps10 = {
  __typename?: 'BooleanrNHkps10';
  error?: Maybe<BooleanrNHkps10ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanrNHkps10ErrorObject = {
  __typename?: 'BooleanrNHkps10ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleansZJvO8fX = {
  __typename?: 'BooleansZJvO8fX';
  error?: Maybe<BooleansZJvO8fXErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleansZJvO8fXErrorObject = {
  __typename?: 'BooleansZJvO8fXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanv1S4Cnlu = {
  __typename?: 'Booleanv1S4Cnlu';
  error?: Maybe<Booleanv1S4CnluErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanv1S4CnluErrorObject = {
  __typename?: 'Booleanv1S4CnluErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanv4WUg746 = {
  __typename?: 'Booleanv4WUg746';
  error?: Maybe<Booleanv4WUg746ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanv4WUg746ErrorObject = {
  __typename?: 'Booleanv4WUg746ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanw0ChTv1J = {
  __typename?: 'Booleanw0ChTV1J';
  error?: Maybe<Booleanw0ChTv1JErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanw0ChTv1JErrorObject = {
  __typename?: 'Booleanw0ChTV1JErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanwV4RxFba = {
  __typename?: 'BooleanwV4RXFba';
  error?: Maybe<BooleanwV4RxFbaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanwV4RxFbaErrorObject = {
  __typename?: 'BooleanwV4RXFbaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanx0foq5fL = {
  __typename?: 'Booleanx0foq5fL';
  error?: Maybe<Booleanx0foq5fLErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanx0foq5fLErrorObject = {
  __typename?: 'Booleanx0foq5fLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxPqLJrSf = {
  __typename?: 'BooleanxPqLJrSF';
  error?: Maybe<BooleanxPqLJrSfErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxPqLJrSfErrorObject = {
  __typename?: 'BooleanxPqLJrSFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxcoarZkm = {
  __typename?: 'BooleanxcoarZkm';
  error?: Maybe<BooleanxcoarZkmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxcoarZkmErrorObject = {
  __typename?: 'BooleanxcoarZkmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzV5gvTxi = {
  __typename?: 'BooleanzV5gvTXI';
  error?: Maybe<BooleanzV5gvTxiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzV5gvTxiErrorObject = {
  __typename?: 'BooleanzV5gvTXIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzqV4Fcfl = {
  __typename?: 'BooleanzqV4FCFL';
  error?: Maybe<BooleanzqV4FcflErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzqV4FcflErrorObject = {
  __typename?: 'BooleanzqV4FCFLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CardHolderStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Inactive = 'Inactive'
}

export type CardVolumeObject = {
  __typename?: 'CardVolumeObject';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type CardVolumeResponseObject = {
  __typename?: 'CardVolumeResponseObject';
  error?: Maybe<CardVolumeResponseObjectErrorObject>;
  object?: Maybe<CardVolumeObject>;
};

export type CardVolumeResponseObjectErrorObject = {
  __typename?: 'CardVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CheckIfResetPasswordIsValidInput = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type CheckoutSessionObject = {
  __typename?: 'CheckoutSessionObject';
  cancelUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CheckoutSessionResponseObject = {
  __typename?: 'CheckoutSessionResponseObject';
  error?: Maybe<CheckoutSessionResponseObjectErrorObject>;
  object?: Maybe<CheckoutSessionObject>;
};

export type CheckoutSessionResponseObjectErrorObject = {
  __typename?: 'CheckoutSessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ChoiceFieldInput = {
  body: Scalars['String'];
  choiceId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceFieldObject = {
  __typename?: 'ChoiceFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceInput = {
  choiceFields: Array<ChoiceFieldInput>;
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  maximum?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChoiceObject = {
  __typename?: 'ChoiceObject';
  choiceFields: Array<ChoiceFieldObject>;
  countAlreadyAnswered?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  maximum?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChunkImportObject = {
  __typename?: 'ChunkImportObject';
  chunkIndex: Scalars['Float'];
  zeffyImport: ZeffyImportObject;
};

export type ChunkImportObjectTHhB8buY = {
  __typename?: 'ChunkImportObjectTHhB8buY';
  error?: Maybe<ChunkImportObjectTHhB8buYErrorObject>;
  object?: Maybe<ChunkImportObject>;
};

export type ChunkImportObjectTHhB8buYErrorObject = {
  __typename?: 'ChunkImportObjectTHhB8buYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ClusterObject = {
  __typename?: 'ClusterObject';
  coordinates: CoordinatesObject;
  count: Scalars['Float'];
  cursorType: CursorType;
  displayedLatitude: Scalars['Float'];
  displayedLongitude: Scalars['Float'];
  id: Scalars['String'];
};

export type ClusterResponseObject = {
  __typename?: 'ClusterResponseObject';
  error?: Maybe<ClusterResponseObjectErrorObject>;
  items?: Maybe<Array<ClusterObject>>;
};

export type ClusterResponseObjectErrorObject = {
  __typename?: 'ClusterResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandLang {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type CommandObject = {
  __typename?: 'CommandObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAtUtc: Scalars['Date'];
  cs?: Maybe<Scalars['String']>;
  currency: AvailablePaymentCurrency;
  discount?: Maybe<DiscountObject>;
  discountAmount: Scalars['Int'];
  discountId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  eligibleAmount: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formLang: CommandLang;
  formType: FormType;
  id: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  locale: Locales;
  organization: OrganizationObject;
  organizationCountry: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  postalCode?: Maybe<Scalars['String']>;
  productBids?: Maybe<Array<ProductBidObject>>;
  productDonation?: Maybe<ProductDonationObject>;
  productTickets?: Maybe<Array<ProductTicketObject>>;
  productsAmount: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  source: CommandSource;
  status: CommandStatus;
  tipAmount: Scalars['Int'];
  tipPercentage: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type CommandObjectKswBq7mL = {
  __typename?: 'CommandObjectKswBQ7mL';
  error?: Maybe<CommandObjectKswBq7mLErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectKswBq7mLErrorObject = {
  __typename?: 'CommandObjectKswBQ7mLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectMzAzltV2 = {
  __typename?: 'CommandObjectMZAzltV2';
  error?: Maybe<CommandObjectMzAzltV2ErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectMzAzltV2ErrorObject = {
  __typename?: 'CommandObjectMZAzltV2ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectXrg6nVs6 = {
  __typename?: 'CommandObjectXRG6nVs6';
  error?: Maybe<CommandObjectXrg6nVs6ErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectXrg6nVs6ErrorObject = {
  __typename?: 'CommandObjectXRG6nVs6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjecth3sc04vI = {
  __typename?: 'CommandObjecth3sc04vI';
  error?: Maybe<CommandObjecth3sc04vIErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjecth3sc04vIErrorObject = {
  __typename?: 'CommandObjecth3sc04vIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectjGnNjvnv = {
  __typename?: 'CommandObjectjGnNJVNV';
  error?: Maybe<CommandObjectjGnNjvnvErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectjGnNjvnvErrorObject = {
  __typename?: 'CommandObjectjGnNJVNVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectyOLci0Fr = {
  __typename?: 'CommandObjectyOLci0FR';
  error?: Maybe<CommandObjectyOLci0FrErrorObject>;
  items?: Maybe<Array<CommandObject>>;
};

export type CommandObjectyOLci0FrErrorObject = {
  __typename?: 'CommandObjectyOLci0FRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseListObjectErrorObject = {
  __typename?: 'CommandResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseObject = {
  __typename?: 'CommandResponseObject';
  error?: Maybe<CommandResponseObjectErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandResponseObjectErrorObject = {
  __typename?: 'CommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandSource {
  FormSubmission = 'FormSubmission',
  Import = 'Import',
  ManualEntry = 'ManualEntry'
}

export enum CommandStatus {
  Archived = 'Archived',
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open',
  Submitted = 'Submitted'
}

export type CommandSubscriptionObject = {
  __typename?: 'CommandSubscriptionObject';
  id: Scalars['String'];
  recurrenceInterval: SubscriptionRecurrenceInterval;
  status?: Maybe<NewSubscriptionStatus>;
  stripeSubscriptionId: Scalars['String'];
};

export type CommandSubscriptionResponseObject = {
  __typename?: 'CommandSubscriptionResponseObject';
  error?: Maybe<CommandSubscriptionResponseObjectErrorObject>;
  object?: Maybe<CommandSubscriptionObject>;
};

export type CommandSubscriptionResponseObjectErrorObject = {
  __typename?: 'CommandSubscriptionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CompleteStripeAuthError {
  ImpossibleToCreateStripeAccount = 'ImpossibleToCreateStripeAccount'
}

export type CompleteStripeAuthResponseObjectErrorObject = {
  __typename?: 'CompleteStripeAuthResponseObjectErrorObject';
  code?: Maybe<CompleteStripeAuthError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ConfirmPaymentIntentInput = {
  paymentIntentId: Scalars['String'];
};

export type ConfirmPaymentIntentObject = {
  __typename?: 'ConfirmPaymentIntentObject';
  nextAction?: Maybe<NextActionObject>;
};

export type ConfirmPaymentIntentObjectResponseObjectErrorObject = {
  __typename?: 'ConfirmPaymentIntentObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactIdWithTagsObject = {
  __typename?: 'ContactIdWithTagsObject';
  id: Scalars['String'];
  tags: Array<TagObject>;
};

export type ContactIdWithTagsObject1XuJIvFe = {
  __typename?: 'ContactIdWithTagsObject1XuJIvFE';
  error?: Maybe<ContactIdWithTagsObject1XuJIvFeErrorObject>;
  items?: Maybe<Array<ContactIdWithTagsObject>>;
};

export type ContactIdWithTagsObject1XuJIvFeErrorObject = {
  __typename?: 'ContactIdWithTagsObject1XuJIvFEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactKeyInfoObject = {
  __typename?: 'ContactKeyInfoObject';
  contactSince?: Maybe<Scalars['Date']>;
  largestDonation?: Maybe<Scalars['Float']>;
  monthlyRecurringContribution?: Maybe<Scalars['Float']>;
  totalContribution?: Maybe<Scalars['Float']>;
  yearlyRecurringContribution?: Maybe<Scalars['Float']>;
};

export type ContactKeyInfoObjectkruN5p8q = {
  __typename?: 'ContactKeyInfoObjectkruN5p8q';
  error?: Maybe<ContactKeyInfoObjectkruN5p8qErrorObject>;
  object?: Maybe<ContactKeyInfoObject>;
};

export type ContactKeyInfoObjectkruN5p8qErrorObject = {
  __typename?: 'ContactKeyInfoObjectkruN5p8qErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject = {
  __typename?: 'ContactObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  donorType?: Maybe<DonorType>;
  firstName?: Maybe<Scalars['String']>;
  hasUnsubscribed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  unsubscribedEmailDeliveryId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  userId: Scalars['String'];
};

export type ContactObjectNd92o8Re = {
  __typename?: 'ContactObjectND92o8Re';
  error?: Maybe<ContactObjectNd92o8ReErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectNd92o8ReErrorObject = {
  __typename?: 'ContactObjectND92o8ReErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectakOhRwy8 = {
  __typename?: 'ContactObjectakOhRwy8';
  error?: Maybe<ContactObjectakOhRwy8ErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectakOhRwy8ErrorObject = {
  __typename?: 'ContactObjectakOhRwy8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectqHaGly5h = {
  __typename?: 'ContactObjectqHaGly5h';
  error?: Maybe<ContactObjectqHaGly5hErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectqHaGly5hErrorObject = {
  __typename?: 'ContactObjectqHaGly5hErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectuHqWw7Gg = {
  __typename?: 'ContactObjectuHqWw7Gg';
  error?: Maybe<ContactObjectuHqWw7GgErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectuHqWw7GgErrorObject = {
  __typename?: 'ContactObjectuHqWw7GgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactResponseObject = {
  __typename?: 'ContactResponseObject';
  error?: Maybe<ContactResponseObjectErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactResponseObjectErrorObject = {
  __typename?: 'ContactResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCardHolderInput = {
  address: Scalars['String'];
  agreedToStripeCardIssuingTermsAt: Scalars['Date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: DateOfBirthInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type CreateDonationAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval?: InputMaybe<ProductDonationRecurrenceInterval>;
  sortIndex?: InputMaybe<Scalars['Float']>;
};

export type CreateDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts: Array<CreateDonationAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  postDonationUrl?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateDraftDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  displayAddressQuestion?: InputMaybe<Scalars['Boolean']>;
  donationFormFields: Array<CreateDonationFormFieldInput>;
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isOnboardingForm?: Scalars['Boolean'];
  isPrimaryCampaign?: InputMaybe<Scalars['Boolean']>;
  isPrimaryTeam?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<CreateLinkedTicketingInput>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  organizationId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  registrationCommandId?: InputMaybe<Scalars['String']>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  registrationOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FormStatus>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['Float']>;
  teamId?: InputMaybe<Scalars['String']>;
  ticketingId?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateFrontendOrderResponseObjectErrorObject = {
  __typename?: 'CreateFrontendOrderResponseObjectErrorObject';
  code?: Maybe<CreateOrderResponseError>;
  data?: Maybe<CreateOrderErrorDataObject>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateIssuingTopupInput = {
  amount: Scalars['Int'];
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
};

export type CreateLinkedTicketingInput = {
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  rates: Array<CreateRateInput>;
};

export type CreateMobileCommandInput = {
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  extraDonation: Scalars['Float'];
  formId: Scalars['String'];
  locale: Locales;
  paymentIntentId: Scalars['String'];
  productDonation?: InputMaybe<CreateMobileProductDonationInput>;
  productTickets?: InputMaybe<Array<CreateMobileTicketInput>>;
  productsAmount: Scalars['Int'];
  tipAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type CreateMobileCommandObject = {
  __typename?: 'CreateMobileCommandObject';
  id: Scalars['String'];
};

export type CreateMobileCommandResponseObject = {
  __typename?: 'CreateMobileCommandResponseObject';
  error?: Maybe<CreateMobileCommandResponseObjectErrorObject>;
  object?: Maybe<CreateMobileCommandObject>;
};

export type CreateMobileCommandResponseObjectErrorObject = {
  __typename?: 'CreateMobileCommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateMobileProductDonationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  inHonourEmailAddress?: InputMaybe<Scalars['String']>;
  inHonourEmailBody?: InputMaybe<Scalars['String']>;
  inHonourName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: InputMaybe<Scalars['String']>;
};

export type CreateMobileTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal: Scalars['Boolean'];
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
};

export type CreateNewOrganizationInput = {
  country: OrganizationCountry;
  organizationName: Scalars['String'];
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOther: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrderErrorDataObject = {
  __typename?: 'CreateOrderErrorDataObject';
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers: Array<AnswerInput>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  email?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Float'];
  firstName?: InputMaybe<Scalars['String']>;
  isTapToPay?: InputMaybe<Scalars['Boolean']>;
  isTipMessageStayFree?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  occurrenceId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tip: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreateOrderResponseError {
  ChoiceIsFull = 'ChoiceIsFull',
  ExpiredEarlyBirdRate = 'ExpiredEarlyBirdRate',
  NoTicketNorExtraDonation = 'NoTicketNorExtraDonation',
  NoTicketSelected = 'NoTicketSelected',
  OverpassedTicketingSeats = 'OverpassedTicketingSeats',
  RateIsFull = 'RateIsFull',
  ShouldSpecifyATicketingOccurrence = 'ShouldSpecifyATicketingOccurrence',
  TicketingIsClosed = 'TicketingIsClosed',
  TicketingIsFull = 'TicketingIsFull',
  TicketingOccurrenceNotFound = 'TicketingOccurrenceNotFound',
  UnknownError = 'UnknownError'
}

export type CreateOrderResponseObject = {
  __typename?: 'CreateOrderResponseObject';
  error?: Maybe<CreateOrderResponseError>;
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  order?: Maybe<OrderObject>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  locale: Locales;
  title: Scalars['String'];
};

export type CreateRateInput = {
  amount: Scalars['Float'];
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photoUrls?: InputMaybe<Array<Scalars['String']>>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields?: InputMaybe<Array<CreateRateFieldInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type CreateReceiptInput = {
  address?: InputMaybe<Scalars['String']>;
  advantageAmount?: InputMaybe<Scalars['Float']>;
  advantageDescription?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annotation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  donationDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isImported?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  issuingAtUtc?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  postalCode?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReceiptStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']>;
  rateId: Scalars['String'];
};

export type CreateTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  postEventBody?: InputMaybe<Scalars['String']>;
  postEventSubject?: InputMaybe<Scalars['String']>;
  postTransactionUrl?: InputMaybe<Scalars['String']>;
  reminderBody?: InputMaybe<Scalars['String']>;
  reminderSubject?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  duplicatedFromId?: InputMaybe<Scalars['String']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isOnboardingForm?: InputMaybe<Scalars['Boolean']>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale: Locales;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder: Array<QuestionInput>;
  questionsPerParticipant: Array<QuestionInput>;
  rates: Array<CreateRateInput>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  reminderSendDateOffset?: InputMaybe<Scalars['Float']>;
  seats?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<FormStatus>;
  ticketingFields: Array<CreateTicketingFieldInput>;
};

export type CreatedCampaignsObject = {
  __typename?: 'CreatedCampaignsObject';
  individualCampaigns?: Maybe<Array<FundraiserDonationFormObject>>;
  team?: Maybe<FundraiserDonationFormObject>;
};

export type CreatedIssuingPayoutInput = {
  amount: Scalars['Float'];
};

export type CreatedIssuingPayoutObject = {
  __typename?: 'CreatedIssuingPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type CreatedIssuingPayoutObject3YzGqYqO = {
  __typename?: 'CreatedIssuingPayoutObject3YzGQYqO';
  error?: Maybe<CreatedIssuingPayoutObject3YzGqYqOErrorObject>;
  object?: Maybe<CreatedIssuingPayoutObject>;
};

export type CreatedIssuingPayoutObject3YzGqYqOErrorObject = {
  __typename?: 'CreatedIssuingPayoutObject3YzGQYqOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CronQueues {
  AuctionsPaymentProcessor = 'AuctionsPaymentProcessor',
  CloseAuctions = 'CloseAuctions',
  GenerateBankStatement = 'GenerateBankStatement',
  GenerateSitemap = 'GenerateSitemap',
  HubspotNurturingDealCreation = 'HubspotNurturingDealCreation',
  InvitationReminder = 'InvitationReminder',
  MarkStaleTransactionsAsFailed = 'MarkStaleTransactionsAsFailed',
  MembershipReminders = 'MembershipReminders',
  NotifyActiveOrganizationsWithoutBankInfo = 'NotifyActiveOrganizationsWithoutBankInfo',
  ProcessBalanceTransfersToIssuing = 'ProcessBalanceTransfersToIssuing',
  ProcessIssuingRewards = 'ProcessIssuingRewards',
  ProcessReferralTransfers = 'ProcessReferralTransfers',
  ProcessScheduledPayouts = 'ProcessScheduledPayouts',
  Reminders = 'Reminders',
  RetryFailedTransfer = 'RetryFailedTransfer',
  SendDailyVisitorReport = 'SendDailyVisitorReport',
  SendEventManagementEmails = 'SendEventManagementEmails',
  SendMonthlyBalanceTransactionsExport = 'SendMonthlyBalanceTransactionsExport',
  SendMonthlyChurnedReport = 'SendMonthlyChurnedReport',
  SendPostEventEmailDeliveries = 'SendPostEventEmailDeliveries',
  SendScheduledEmailDeliveries = 'SendScheduledEmailDeliveries',
  SendSuggestDesktopCompletionEmail = 'SendSuggestDesktopCompletionEmail',
  SendWeeklyChurnedReport = 'SendWeeklyChurnedReport',
  TriggerAwaitingWithdrawalRefunds = 'TriggerAwaitingWithdrawalRefunds',
  UpdateFraudulentTransaction = 'UpdateFraudulentTransaction',
  UpdateMissingIrsInfo = 'UpdateMissingIrsInfo'
}

export type CurrentlyActiveOrganizationObject = {
  __typename?: 'CurrentlyActiveOrganizationObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentlyActiveOrganizationResponseListObject = {
  __typename?: 'CurrentlyActiveOrganizationResponseListObject';
  error?: Maybe<OrganizationResponseListObjectErrorObject>;
  items?: Maybe<Array<CurrentlyActiveOrganizationObject>>;
};

export enum CursorType {
  Organization = 'Organization',
  Ticketing = 'Ticketing'
}

export type DashboardCommandFormListResponse = {
  __typename?: 'DashboardCommandFormListResponse';
  error?: Maybe<DashboardCommandFormListResponseErrorObject>;
  items?: Maybe<Array<FormObject>>;
};

export type DashboardCommandFormListResponseErrorObject = {
  __typename?: 'DashboardCommandFormListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRateObject = {
  __typename?: 'DashboardCommandRateObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DashboardCommandRatesListResponseObject = {
  __typename?: 'DashboardCommandRatesListResponseObject';
  error?: Maybe<DashboardCommandRatesListResponseObjectErrorObject>;
  items?: Maybe<Array<DashboardCommandRatesObject>>;
};

export type DashboardCommandRatesListResponseObjectErrorObject = {
  __typename?: 'DashboardCommandRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRatesObject = {
  __typename?: 'DashboardCommandRatesObject';
  commandId: Scalars['String'];
  rates: Array<DashboardCommandRateObject>;
};

export type DashboardCountObject = {
  __typename?: 'DashboardCountObject';
  count: Scalars['Float'];
};

export type DashboardCountObjectZahfrMFl = {
  __typename?: 'DashboardCountObjectZahfrMFl';
  error?: Maybe<DashboardCountObjectZahfrMFlErrorObject>;
  object?: Maybe<DashboardCountObject>;
};

export type DashboardCountObjectZahfrMFlErrorObject = {
  __typename?: 'DashboardCountObjectZahfrMFlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCountResponseObjectErrorObject = {
  __typename?: 'DashboardCountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DashboardPaymentStatus {
  DisputeLost = 'DisputeLost',
  Disputed = 'Disputed',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  PastDue = 'PastDue',
  Processing = 'Processing',
  Stopped = 'Stopped',
  Succeeded = 'Succeeded',
  Unprocessed = 'Unprocessed',
  Yearly = 'Yearly'
}

export type DashboardTransactionListResponseObjectErrorObject = {
  __typename?: 'DashboardTransactionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObject = {
  __typename?: 'DashboardTransactionObject';
  commandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  displayStatus: DashboardPaymentStatus;
  email?: Maybe<Scalars['String']>;
  eventEndAt?: Maybe<Scalars['Date']>;
  eventStartAt?: Maybe<Scalars['Date']>;
  extraDonation?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formType: FormType;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isNewRecurringDonation?: Maybe<Scalars['Boolean']>;
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nextRenewalAt?: Maybe<Scalars['Date']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payoutDate?: Maybe<Scalars['Date']>;
  receiptId?: Maybe<Scalars['String']>;
  recurrenceInterval?: Maybe<SubscriptionRecurrenceInterval>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount: Scalars['Int'];
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatus;
  stoppedAt?: Maybe<Scalars['Date']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<NewSubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type DashboardTransactionObjectK0Jz2ahO = {
  __typename?: 'DashboardTransactionObjectK0JZ2ahO';
  error?: Maybe<DashboardTransactionObjectK0Jz2ahOErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectK0Jz2ahOErrorObject = {
  __typename?: 'DashboardTransactionObjectK0JZ2ahOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObjectQfg5Kqrm = {
  __typename?: 'DashboardTransactionObjectQFG5Kqrm';
  error?: Maybe<DashboardTransactionObjectQfg5KqrmErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectQfg5KqrmErrorObject = {
  __typename?: 'DashboardTransactionObjectQFG5KqrmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DateOfBirthInput = {
  day: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

export type DeactivateAccountInput = {
  reason: Scalars['String'];
};

export type DeactiveTwoFactorAuthInput = {
  password: Scalars['String'];
};

export type DeleteUserAccountInput = {
  reason: Scalars['String'];
};

export type DiscountObject = {
  __typename?: 'DiscountObject';
  amount: Scalars['Float'];
  body: Scalars['String'];
  id: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  remainingUses?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  ticketingId: Scalars['String'];
  type: DiscountType;
};

export type DiscountResponseObject = {
  __typename?: 'DiscountResponseObject';
  error?: Maybe<DiscountResponseObjectErrorObject>;
  object?: Maybe<DiscountObject>;
};

export type DiscountResponseObjectErrorObject = {
  __typename?: 'DiscountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DiscountType {
  Dollars = 'Dollars',
  Percentage = 'Percentage'
}

export type DonationFormAmountInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormAmountObject = {
  __typename?: 'DonationFormAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormCampaignFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  search: Scalars['String'];
};

export enum DonationFormCategory {
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  PeerToPeerCampaign = 'PeerToPeerCampaign'
}

export type DonationFormFieldFromCampaignObject = {
  __typename?: 'DonationFormFieldFromCampaignObject';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type DonationFormFieldObject = {
  __typename?: 'DonationFormFieldObject';
  chequeDescription?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  description: Scalars['String'];
  donationFormAmounts?: Maybe<Array<DonationFormAmountObject>>;
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailObject?: Maybe<Scalars['String']>;
  fundraiserEmailBody?: Maybe<Scalars['String']>;
  fundraisingRegistrationWording?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  postDonationUrl?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  ticketingRegistrationWording?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DonationFormFromCampaignObject = {
  __typename?: 'DonationFormFromCampaignObject';
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  donationFormFields?: Maybe<Array<DonationFormFieldFromCampaignObject>>;
  id: Scalars['String'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject = {
  __typename?: 'DonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject080i07U8 = {
  __typename?: 'DonationFormObject080i07U8';
  error?: Maybe<DonationFormObject080i07U8ErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObject080i07U8ErrorObject = {
  __typename?: 'DonationFormObject080i07U8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject4Vdoi5ad = {
  __typename?: 'DonationFormObject4VDOI5ad';
  error?: Maybe<DonationFormObject4Vdoi5adErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject4Vdoi5adErrorObject = {
  __typename?: 'DonationFormObject4VDOI5adErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject7MHfe0A9 = {
  __typename?: 'DonationFormObject7MHfe0A9';
  error?: Maybe<DonationFormObject7MHfe0A9ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject7MHfe0A9ErrorObject = {
  __typename?: 'DonationFormObject7MHfe0A9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject8Fn8NTjX = {
  __typename?: 'DonationFormObject8Fn8NTjX';
  error?: Maybe<DonationFormObject8Fn8NTjXErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject8Fn8NTjXErrorObject = {
  __typename?: 'DonationFormObject8Fn8NTjXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObject8lTdnXcM = {
  __typename?: 'DonationFormObject8lTdnXcM';
  error?: Maybe<DonationFormObject8lTdnXcMErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject8lTdnXcMErrorObject = {
  __typename?: 'DonationFormObject8lTdnXcMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectDj51G6jA = {
  __typename?: 'DonationFormObjectDj51G6jA';
  error?: Maybe<DonationFormObjectDj51G6jAErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectDj51G6jAErrorObject = {
  __typename?: 'DonationFormObjectDj51G6jAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectLWa0y6eX = {
  __typename?: 'DonationFormObjectLWa0y6eX';
  error?: Maybe<DonationFormObjectLWa0y6eXErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectLWa0y6eXErrorObject = {
  __typename?: 'DonationFormObjectLWa0y6eXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectMmhmFsh4 = {
  __typename?: 'DonationFormObjectMMHMFsh4';
  error?: Maybe<DonationFormObjectMmhmFsh4ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectMmhmFsh4ErrorObject = {
  __typename?: 'DonationFormObjectMMHMFsh4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectOrZbBzPi = {
  __typename?: 'DonationFormObjectOrZbBzPi';
  error?: Maybe<DonationFormObjectOrZbBzPiErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectOrZbBzPiErrorObject = {
  __typename?: 'DonationFormObjectOrZbBzPiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectWNvj0Abb = {
  __typename?: 'DonationFormObjectWNvj0Abb';
  error?: Maybe<DonationFormObjectWNvj0AbbErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectWNvj0AbbErrorObject = {
  __typename?: 'DonationFormObjectWNvj0AbbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectWUzoYc9m = {
  __typename?: 'DonationFormObjectWUzoYc9m';
  error?: Maybe<DonationFormObjectWUzoYc9mErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectWUzoYc9mErrorObject = {
  __typename?: 'DonationFormObjectWUzoYc9mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectZaLflz7f = {
  __typename?: 'DonationFormObjectZALflz7f';
  error?: Maybe<DonationFormObjectZaLflz7fErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectZaLflz7fErrorObject = {
  __typename?: 'DonationFormObjectZALflz7fErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectgAVgLBt5 = {
  __typename?: 'DonationFormObjectgAVgLBt5';
  error?: Maybe<DonationFormObjectgAVgLBt5ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectgAVgLBt5ErrorObject = {
  __typename?: 'DonationFormObjectgAVgLBt5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjecthUik34Xh = {
  __typename?: 'DonationFormObjecthUik34Xh';
  error?: Maybe<DonationFormObjecthUik34XhErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjecthUik34XhErrorObject = {
  __typename?: 'DonationFormObjecthUik34XhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectie9XmMvw = {
  __typename?: 'DonationFormObjectie9XMMvw';
  error?: Maybe<DonationFormObjectie9XmMvwErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectie9XmMvwErrorObject = {
  __typename?: 'DonationFormObjectie9XMMvwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectkWWwSv3s = {
  __typename?: 'DonationFormObjectkWWwSv3s';
  error?: Maybe<DonationFormObjectkWWwSv3sErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectkWWwSv3sErrorObject = {
  __typename?: 'DonationFormObjectkWWwSv3sErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectlgFmnhUk = {
  __typename?: 'DonationFormObjectlgFmnhUK';
  error?: Maybe<DonationFormObjectlgFmnhUkErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectlgFmnhUkErrorObject = {
  __typename?: 'DonationFormObjectlgFmnhUKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectmehoCDoT = {
  __typename?: 'DonationFormObjectmehoCDoT';
  error?: Maybe<DonationFormObjectmehoCDoTErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectmehoCDoTErrorObject = {
  __typename?: 'DonationFormObjectmehoCDoTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectnh7Fzc1c = {
  __typename?: 'DonationFormObjectnh7FZC1c';
  error?: Maybe<DonationFormObjectnh7Fzc1cErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectnh7Fzc1cErrorObject = {
  __typename?: 'DonationFormObjectnh7FZC1cErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectqFshbS6E = {
  __typename?: 'DonationFormObjectqFshbS6E';
  error?: Maybe<DonationFormObjectqFshbS6EErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjectqFshbS6EErrorObject = {
  __typename?: 'DonationFormObjectqFshbS6EErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectxYXd0OUg = {
  __typename?: 'DonationFormObjectxYXd0OUg';
  error?: Maybe<DonationFormObjectxYXd0OUgErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectxYXd0OUgErrorObject = {
  __typename?: 'DonationFormObjectxYXd0OUgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectz1Xlj2El = {
  __typename?: 'DonationFormObjectz1Xlj2EL';
  error?: Maybe<DonationFormObjectz1Xlj2ElErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectz1Xlj2ElErrorObject = {
  __typename?: 'DonationFormObjectz1Xlj2ELErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormRecurrence {
  monthly = 'monthly',
  weekly = 'weekly'
}

export type DonationFormResponseObject = {
  __typename?: 'DonationFormResponseObject';
  error?: Maybe<DonationFormResponseObjectErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormResponseObjectErrorObject = {
  __typename?: 'DonationFormResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormSummaryObject = {
  __typename?: 'DonationFormSummaryObject';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DonationFormTableResponseListObjectErrorObject = {
  __typename?: 'DonationFormTableResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormTemplateType {
  givingTuesday = 'givingTuesday',
  onboarding = 'onboarding'
}

export type DonationObject = {
  __typename?: 'DonationObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationForm: DonationFormObject;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTransactionId?: Maybe<Scalars['String']>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  postalCode?: Maybe<Scalars['String']>;
  recurrence?: Maybe<DonationFormRecurrence>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  region?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type DonationResponseListObjectErrorObject = {
  __typename?: 'DonationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationResponseObjectErrorObject = {
  __typename?: 'DonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationCountry: OrganizationCountry;
};

export enum DonorType {
  Member = 'Member',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Prospect = 'Prospect',
  Returning = 'Returning',
  Yearly = 'Yearly'
}

export type DonorVolumeListResponseObject = {
  __typename?: 'DonorVolumeListResponseObject';
  error?: Maybe<DonorVolumeListResponseObjectErrorObject>;
  items?: Maybe<Array<DonorVolumeObject>>;
};

export type DonorVolumeListResponseObjectErrorObject = {
  __typename?: 'DonorVolumeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorVolumeObject = {
  __typename?: 'DonorVolumeObject';
  amount: Scalars['Float'];
  currency: AvailablePaymentCurrency;
};

export type EditContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type EditDiscountInput = {
  amount: Scalars['Float'];
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  type: DiscountType;
};

export type EditDonationFormAmountInput = {
  toDelete: Array<DonationFormAmountInput>;
  toSave: Array<UpdateDonationFormAmountInput>;
};

export type EditDonationFormAmountTranslationInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type EditDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  donationFormFields: Array<EditDonationFormFieldInput>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<EditLinkedTicketingInput>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrence?: InputMaybe<DonationFormRecurrence>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['Float']>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export type EditDonationFormTranslationsInput = {
  fields: TranslationsDonationFormFieldInput;
  translations: TranslationsDonationFormFieldInput;
};

export type EditLinkedTicketingInput = {
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates: Array<EditRateInput>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
};

export type EditPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type EditRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditRateInput = {
  allowAutomaticRenewal?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields: Array<EditRateFieldInput>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type EditReminderDataInput = {
  id: Scalars['String'];
  reminderBody: Scalars['String'];
  reminderSubject: Scalars['String'];
};

export type EditSessionOnSubmission = {
  durationOnPage: Scalars['Float'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EditTagInput = {
  tagId: Scalars['String'];
  value: Scalars['String'];
};

export type EditTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<EditDiscountInput>>;
  discountsToDelete?: InputMaybe<Array<Scalars['String']>>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation?: InputMaybe<Scalars['Boolean']>;
  generateExtraDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  generateQrCode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder?: InputMaybe<Array<QuestionInput>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates?: InputMaybe<Array<EditRateInput>>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  thermometerTarget?: InputMaybe<Scalars['Float']>;
  ticketingFields?: InputMaybe<Array<EditTicketingFieldInput>>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerPdf?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export enum EmailContentFlow {
  Builder = 'Builder',
  Custom = 'Custom',
  Quick = 'Quick'
}

export type EmailContentObject = {
  __typename?: 'EmailContentObject';
  attachmentUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  emailType?: Maybe<EmailContentType>;
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
};

export enum EmailContentType {
  BLAST = 'BLAST',
  FOLLOW_UP = 'FOLLOW_UP',
  INVITATION = 'INVITATION',
  POST_EVENT = 'POST_EVENT',
  REMINDER = 'REMINDER'
}

export type EmailDeliveryObject = {
  __typename?: 'EmailDeliveryObject';
  body: Scalars['String'];
  clickedRatio: Scalars['Float'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<EmailContentObject>;
  emailContentId?: Maybe<Scalars['String']>;
  emailsCount: Scalars['Float'];
  id: Scalars['String'];
  isPrefill: Scalars['Boolean'];
  locale: Locales;
  openedRatio: Scalars['Float'];
  organization: OrganizationObject;
  recipientEmailStatus?: Maybe<EmailStatus>;
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  recipients?: Maybe<Array<RecipientObject>>;
  scheduledFor?: Maybe<Scalars['Date']>;
  sender: UserObject;
  sentAt?: Maybe<Scalars['Date']>;
  status: EmailDeliveryStatus;
  subject: Scalars['String'];
};

export type EmailDeliveryObjectSjSwafja = {
  __typename?: 'EmailDeliveryObjectSjSWAFJA';
  error?: Maybe<EmailDeliveryObjectSjSwafjaErrorObject>;
  object?: Maybe<EmailDeliveryObject>;
};

export type EmailDeliveryObjectSjSwafjaErrorObject = {
  __typename?: 'EmailDeliveryObjectSjSWAFJAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailDeliveryResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type EmailDeliveryTableListResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailStatus {
  AwaitingProcessing = 'AwaitingProcessing',
  Bounced = 'Bounced',
  Clicked = 'Clicked',
  Delivered = 'Delivered',
  Invalid = 'Invalid',
  Opened = 'Opened',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum EmailTemplateErrorCode {
  NotFound = 'NotFound'
}

export type EmailTemplateObject = {
  __typename?: 'EmailTemplateObject';
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  subject: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplateResponseListObject = {
  __typename?: 'EmailTemplateResponseListObject';
  error?: Maybe<EmailTemplateResponseListObjectErrorObject>;
  items?: Maybe<Array<EmailTemplateObject>>;
};

export type EmailTemplateResponseListObjectErrorObject = {
  __typename?: 'EmailTemplateResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailTemplateResponseObject = {
  __typename?: 'EmailTemplateResponseObject';
  error?: Maybe<EmailTemplateResponseObjectErrorObject>;
  object?: Maybe<EmailTemplateObject>;
};

export type EmailTemplateResponseObjectErrorObject = {
  __typename?: 'EmailTemplateResponseObjectErrorObject';
  code?: Maybe<EmailTemplateErrorCode>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailType {
  VisitorDailyEmail = 'VisitorDailyEmail',
  VisitorMonthlyEmail = 'VisitorMonthlyEmail',
  VisitorWeeklyEmail = 'VisitorWeeklyEmail'
}

export type EmailsCountByStatusObject = {
  __typename?: 'EmailsCountByStatusObject';
  count: Scalars['Float'];
  status: Scalars['String'];
};

export type EmailsCountByStatusTableListResponseObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObject';
  error?: Maybe<EmailsCountByStatusTableListResponseObjectErrorObject>;
  filteredCount: Scalars['Float'];
  hasMore: Scalars['Boolean'];
  items?: Maybe<Array<EmailsCountByStatusObject>>;
  totalCount: Scalars['Float'];
};

export type EmailsCountByStatusTableListResponseObjectErrorObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EncryptedPaymentIntentObject = {
  __typename?: 'EncryptedPaymentIntentObject';
  clientSecret: Scalars['String'];
  encryptedPaymentIntentId: Scalars['String'];
};

export type EncryptedPaymentIntentResponseObjectErrorObject = {
  __typename?: 'EncryptedPaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EventDateRange = {
  __typename?: 'EventDateRange';
  endDateUtc: Scalars['Date'];
  startDateUtc: Scalars['Date'];
};

export enum ExpectedAmountSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export type ExpiredEarlyBirdErrorObject = {
  __typename?: 'ExpiredEarlyBirdErrorObject';
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ExportObject = {
  __typename?: 'ExportObject';
  export?: Maybe<Scalars['String']>;
  isExportSentByEmail: Scalars['Boolean'];
};

export type ExportObjectB3Cs6kOb = {
  __typename?: 'ExportObjectB3Cs6kOb';
  error?: Maybe<ExportObjectB3Cs6kObErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportObjectB3Cs6kObErrorObject = {
  __typename?: 'ExportObjectB3Cs6kObErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExportParametersInput = {
  locale: Locales;
  timezone: Scalars['String'];
};

export type ExportResponseObject = {
  __typename?: 'ExportResponseObject';
  error?: Maybe<ExportResponseObjectErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportResponseObjectErrorObject = {
  __typename?: 'ExportResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type ExternalAccountObject = {
  __typename?: 'ExternalAccountObject';
  accountName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<AvailablePaymentCurrency>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};

export type ExternalAccountObjectWuGh1tvy = {
  __typename?: 'ExternalAccountObjectWuGH1tvy';
  error?: Maybe<ExternalAccountObjectWuGh1tvyErrorObject>;
  object?: Maybe<ExternalAccountObject>;
};

export type ExternalAccountObjectWuGh1tvyErrorObject = {
  __typename?: 'ExternalAccountObjectWuGH1tvyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FeatureFlagName {
  FeatureToggleBadTipperDisclaimer = 'FeatureToggleBadTipperDisclaimer',
  IsAlgoliaForFormSearch = 'IsAlgoliaForFormSearch',
  IsAuctionRebiddingDisabled = 'IsAuctionRebiddingDisabled',
  IsBookADemoDisplayedWebflow = 'IsBookADemoDisplayedWebflow',
  IsCloudflareApiEnabled = 'IsCloudflareApiEnabled',
  IsFundraiserFormEditDisabled = 'IsFundraiserFormEditDisabled',
  IsHubspotActiveInStage = 'IsHubspotActiveInStage',
  IsNewMobileFormCreationFlow = 'IsNewMobileFormCreationFlow',
  IsPaymentByPadAllowed = 'IsPaymentByPadAllowed',
  IsZeffyUnderAttackFromCardTesting = 'IsZeffyUnderAttackFromCardTesting',
  LearnMoreOnTipMessage = 'LearnMoreOnTipMessage',
  Test = 'Test',
  TipSuggestionBigAmountsLargerThan400 = 'TipSuggestionBigAmountsLargerThan400',
  TipSuggestionBigAmountsLargerThan1000 = 'TipSuggestionBigAmountsLargerThan1000',
  TipSuggestionExperience = 'TipSuggestionExperience',
  TipSuggestionSmallAmounts = 'TipSuggestionSmallAmounts'
}

export type FeatureFlagTreatmentObject = {
  __typename?: 'FeatureFlagTreatmentObject';
  name: FeatureFlagName;
  treatment: Scalars['Boolean'];
};

export enum FeatureFlagValue {
  A = 'A',
  B = 'B',
  C = 'C',
  Control = 'Control',
  D = 'D',
  E = 'E',
  F = 'F'
}

export type FirstFormObject = {
  __typename?: 'FirstFormObject';
  formCategory: FormCategory;
  formStatus: FormStatus;
  formType: FormType;
  id: Scalars['String'];
  path: Scalars['String'];
};

export enum FormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  PeerToPeerCampaign = 'PeerToPeerCampaign',
  Shop = 'Shop'
}

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type FormInput = {
  formType: FormType;
  id: Scalars['String'];
};

export type FormObject = {
  __typename?: 'FormObject';
  fields: Array<FormField>;
  formLink: Scalars['String'];
  formType: FormType;
  id: Scalars['String'];
  volume: Scalars['Float'];
};

export enum FormStatus {
  Active = 'Active',
  Draft = 'Draft'
}

export type FormTitle = {
  __typename?: 'FormTitle';
  fields: Array<FormField>;
  id: Scalars['String'];
};

export type FormTitleListResponse = {
  __typename?: 'FormTitleListResponse';
  error?: Maybe<FormTitleListResponseErrorObject>;
  items?: Maybe<Array<FormTitle>>;
};

export type FormTitleListResponseErrorObject = {
  __typename?: 'FormTitleListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FormType {
  DonationForm = 'DonationForm',
  Ticketing = 'Ticketing'
}

export enum FrequencyFilter {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type FrontendFormObject = {
  __typename?: 'FrontendFormObject';
  auctionEndDate?: Maybe<Scalars['Date']>;
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  fields: Array<FormField>;
  formCategory: FormCategory;
  formType: FormType;
  generateQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPrimaryCampaign?: Maybe<Scalars['Boolean']>;
  isPrimaryTeam?: Maybe<Scalars['Boolean']>;
  isRegistration?: Maybe<Scalars['Boolean']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  rates?: Maybe<Array<TicketingDetailsRate>>;
  seats?: Maybe<Scalars['Int']>;
  status: FormStatus;
  teamId?: Maybe<Scalars['String']>;
};

export type FrontendFormObject9rrYt8X0 = {
  __typename?: 'FrontendFormObject9rrYt8X0';
  error?: Maybe<FrontendFormObject9rrYt8X0ErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObject9rrYt8X0ErrorObject = {
  __typename?: 'FrontendFormObject9rrYt8X0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectFormObjectResponseObjectErrorObject = {
  __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseListObject = {
  __typename?: 'FrontendFormObjectResponseListObject';
  error?: Maybe<FrontendFormObjectResponseListObjectErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectResponseListObjectErrorObject = {
  __typename?: 'FrontendFormObjectResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseObject = {
  __typename?: 'FrontendFormObjectResponseObject';
  error?: Maybe<FrontendFormObjectFormObjectResponseObjectErrorObject>;
  object?: Maybe<FrontendFormObject>;
};

export type FullChoiceErrorObject = {
  __typename?: 'FullChoiceErrorObject';
  id: Scalars['String'];
  remainingAnswers: Scalars['Float'];
};

export type FullRateErrorObject = {
  __typename?: 'FullRateErrorObject';
  id: Scalars['String'];
  remainingTickets: Scalars['Float'];
};

export type FundraiserDonationFormObject = {
  __typename?: 'FundraiserDonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isFundraiserFormEditDisabled: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type GetBalanceTransactionDetailsObject = {
  __typename?: 'GetBalanceTransactionDetailsObject';
  id: Scalars['String'];
  sourceIssuingTransaction?: Maybe<GetBalanceTransactionIssuingTransactionObject>;
  sourcePayment?: Maybe<GetBalanceTransactionPaymentObject>;
  sourcePayout?: Maybe<GetBalanceTransactionPayoutObject>;
};

export type GetBalanceTransactionDetailsObject6xKp8Ub7 = {
  __typename?: 'GetBalanceTransactionDetailsObject6xKp8UB7';
  error?: Maybe<GetBalanceTransactionDetailsObject6xKp8Ub7ErrorObject>;
  object?: Maybe<GetBalanceTransactionDetailsObject>;
};

export type GetBalanceTransactionDetailsObject6xKp8Ub7ErrorObject = {
  __typename?: 'GetBalanceTransactionDetailsObject6xKp8UB7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetBalanceTransactionIssuingTransactionObject = {
  __typename?: 'GetBalanceTransactionIssuingTransactionObject';
  authorizationCreatedAtUtc: Scalars['Date'];
  city?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  merchantName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type GetBalanceTransactionPaymentObject = {
  __typename?: 'GetBalanceTransactionPaymentObject';
  createdAtUtc: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  formName: Scalars['String'];
  formType: FormType;
  lastName: Scalars['String'];
  paymentMethod: PaymentMethod;
};

export type GetBalanceTransactionPayoutObject = {
  __typename?: 'GetBalanceTransactionPayoutObject';
  arrivalDate: Scalars['Date'];
};

export type GetCurrentUserFrontendObject = {
  __typename?: 'GetCurrentUserFrontendObject';
  connectedAsAdministrator: Scalars['Boolean'];
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  currentOrganizationDetails?: Maybe<OrganizationDetailsObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasAnyBankPermission: Scalars['Boolean'];
  hasDismissedEmailInfobox: Scalars['Boolean'];
  hasDismissedEmailTemplatePrompt: Scalars['Boolean'];
  hasDismissedReferralPromptAtFirstPayout: Scalars['Boolean'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isBankChangeSmsMfaActivated: Scalars['Boolean'];
  isLoginSmsMfaActivated: Scalars['Boolean'];
  isMigratedOnStytch: Scalars['Boolean'];
  isOnboardedOnCheckinManager: Scalars['Boolean'];
  isTwoFactorActivated: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  organizationGroups: Array<OrganizationGroupObject>;
  phone?: Maybe<Scalars['String']>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  zeffyAdministrator: Scalars['Boolean'];
};

export type GetCurrentUserFrontendObjectrLvvjQ6I = {
  __typename?: 'GetCurrentUserFrontendObjectrLvvjQ6I';
  error?: Maybe<GetCurrentUserFrontendObjectrLvvjQ6IErrorObject>;
  object?: Maybe<GetCurrentUserFrontendObject>;
};

export type GetCurrentUserFrontendObjectrLvvjQ6IErrorObject = {
  __typename?: 'GetCurrentUserFrontendObjectrLvvjQ6IErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetCurrentUserObject = {
  __typename?: 'GetCurrentUserObject';
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationGroups: Array<OrganizationGroupObject>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
};

export type GetCurrentUserResponseObjectErrorObject = {
  __typename?: 'GetCurrentUserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetDonationFormsFromCampaignObject = {
  __typename?: 'GetDonationFormsFromCampaignObject';
  hasMore: Scalars['Boolean'];
  individual: Array<DonationFormFromCampaignObject>;
  team: Array<DonationFormFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectcAsEzHte = {
  __typename?: 'GetDonationFormsFromCampaignObjectcAsEZHte';
  error?: Maybe<GetDonationFormsFromCampaignObjectcAsEzHteErrorObject>;
  object?: Maybe<GetDonationFormsFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectcAsEzHteErrorObject = {
  __typename?: 'GetDonationFormsFromCampaignObjectcAsEZHteErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum GetEmailDeliveryError {
  BelongToMyOtherOrganization = 'BelongToMyOtherOrganization'
}

export type GetEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'GetEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<GetEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetEphemeralKeyInput = {
  nonce: Scalars['String'];
};

export type GetEphemeralKeyObject = {
  __typename?: 'GetEphemeralKeyObject';
  ephemeralKeySecret: Scalars['String'];
};

export type GetEphemeralKeyObject4ItMbmwp = {
  __typename?: 'GetEphemeralKeyObject4ItMBMWP';
  error?: Maybe<GetEphemeralKeyObject4ItMbmwpErrorObject>;
  object?: Maybe<GetEphemeralKeyObject>;
};

export type GetEphemeralKeyObject4ItMbmwpErrorObject = {
  __typename?: 'GetEphemeralKeyObject4ItMBMWPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetFirstTransactionCreatedAtUtcResponseObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject';
  createdAtUtc?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
};

export type GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrl = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrl';
  error?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrlErrorObject>;
  object?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject>;
};

export type GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrlErrorObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingCardObject = {
  __typename?: 'GetIssuingCardObject';
  issuingCard?: Maybe<IssuingCardObject>;
};

export type GetIssuingCardObjectLyp84duj = {
  __typename?: 'GetIssuingCardObjectLYP84duj';
  error?: Maybe<GetIssuingCardObjectLyp84dujErrorObject>;
  object?: Maybe<GetIssuingCardObject>;
};

export type GetIssuingCardObjectLyp84dujErrorObject = {
  __typename?: 'GetIssuingCardObjectLYP84dujErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingRewardTransactionsObject = {
  __typename?: 'GetIssuingRewardTransactionsObject';
  amount: Scalars['Float'];
  disputeCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
};

export type GetIssuingRewardTransactionsObjectge8eqngD = {
  __typename?: 'GetIssuingRewardTransactionsObjectge8eqngD';
  error?: Maybe<GetIssuingRewardTransactionsObjectge8eqngDErrorObject>;
  object?: Maybe<GetIssuingRewardTransactionsObject>;
};

export type GetIssuingRewardTransactionsObjectge8eqngDErrorObject = {
  __typename?: 'GetIssuingRewardTransactionsObjectge8eqngDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetMarkerHoverDetailsInput = {
  cursorType: CursorType;
  id: Scalars['String'];
};

export type GetMarkersInput = {
  lat: LatLngBoundariesInput;
  lng: LatLngBoundariesInput;
};

export type GetOrganizationSummaryObject = {
  __typename?: 'GetOrganizationSummaryObject';
  address: Scalars['String'];
  lastDonationForm?: Maybe<DonationFormSummaryObject>;
  lastTicketing?: Maybe<TicketingSummaryObject>;
  url?: Maybe<Scalars['String']>;
};

export type GetOrganizationSummaryResponseObject = {
  __typename?: 'GetOrganizationSummaryResponseObject';
  error?: Maybe<GetOrganizationSummaryResponseObjectErrorObject>;
  object?: Maybe<GetOrganizationSummaryObject>;
};

export type GetOrganizationSummaryResponseObjectErrorObject = {
  __typename?: 'GetOrganizationSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetReferredOrganizationInput = {
  organizationId: Scalars['String'];
};

export type GetScheduledPayoutObject = {
  __typename?: 'GetScheduledPayoutObject';
  payout?: Maybe<ScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectcCnZzTpv = {
  __typename?: 'GetScheduledPayoutObjectcCnZzTpv';
  error?: Maybe<GetScheduledPayoutObjectcCnZzTpvErrorObject>;
  object?: Maybe<GetScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectcCnZzTpvErrorObject = {
  __typename?: 'GetScheduledPayoutObjectcCnZzTpvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetTipSuggestionsInput = {
  amount: Scalars['Float'];
  tipModelExperience?: InputMaybe<TipModelExperience>;
};

export type GetTransactionsForIssuingBalanceTransferObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject';
  endDate?: Maybe<Scalars['Date']>;
  volume: Scalars['Float'];
};

export type GetTransactionsForIssuingBalanceTransferObject8G7Mad3f = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject8G7MAD3f';
  error?: Maybe<GetTransactionsForIssuingBalanceTransferObject8G7Mad3fErrorObject>;
  object?: Maybe<GetTransactionsForIssuingBalanceTransferObject>;
};

export type GetTransactionsForIssuingBalanceTransferObject8G7Mad3fErrorObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject8G7MAD3fErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HasEmailDeliveryObject = {
  __typename?: 'HasEmailDeliveryObject';
  draft: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  sent: Scalars['Boolean'];
};

export type HasEmailDeliveryObjectWWkFOy8H = {
  __typename?: 'HasEmailDeliveryObjectWWkFOy8H';
  error?: Maybe<HasEmailDeliveryObjectWWkFOy8HErrorObject>;
  object?: Maybe<HasEmailDeliveryObject>;
};

export type HasEmailDeliveryObjectWWkFOy8HErrorObject = {
  __typename?: 'HasEmailDeliveryObjectWWkFOy8HErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  frequency: FrequencyFilter;
  startDate: Scalars['Date'];
};

export type HomeGraphObject = {
  __typename?: 'HomeGraphObject';
  date: Scalars['Date'];
  volume: Scalars['Int'];
};

export type HomeGraphObjectResponseObjectErrorObject = {
  __typename?: 'HomeGraphObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphResponseListObject = {
  __typename?: 'HomeGraphResponseListObject';
  error?: Maybe<HomeGraphResponseListObjectErrorObject>;
  items?: Maybe<Array<HomeGraphObject>>;
};

export type HomeGraphResponseListObjectErrorObject = {
  __typename?: 'HomeGraphResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeMetricsInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type HubspotProperty = {
  propertyName: Scalars['String'];
  propertyValue: Scalars['String'];
};

export type ImportCheckErrorObject = {
  __typename?: 'ImportCheckErrorObject';
  emptyColumns: Array<Scalars['String']>;
  invalidColumns: Array<Scalars['String']>;
  row: Scalars['Float'];
};

export enum ImportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum ImportType {
  Contact = 'Contact',
  Payment = 'Payment'
}

export type ImportUsersGoogleSheetObject = {
  __typename?: 'ImportUsersGoogleSheetObject';
  addedContactCount?: Maybe<Scalars['Float']>;
  addedPaymentCount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ImportCheckErrorObject>>;
  id?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ImportStatus>;
};

export type ImportUsersGoogleSheetObjectjBIuSbrW = {
  __typename?: 'ImportUsersGoogleSheetObjectjBIuSbrW';
  error?: Maybe<ImportUsersGoogleSheetObjectjBIuSbrWErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectjBIuSbrWErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectjBIuSbrWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetObjectjOdKwQcI = {
  __typename?: 'ImportUsersGoogleSheetObjectjOdKwQcI';
  error?: Maybe<ImportUsersGoogleSheetObjectjOdKwQcIErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectjOdKwQcIErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectjOdKwQcIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetResponseObjectErrorObject = {
  __typename?: 'ImportUsersGoogleSheetResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IncreasePayoutAmountInput = {
  amount: Scalars['Float'];
  payoutId: Scalars['String'];
};

export type IncreasePayoutAmountObject = {
  __typename?: 'IncreasePayoutAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type IncreasePayoutAmountObjectCGxqNKsj = {
  __typename?: 'IncreasePayoutAmountObjectCGxqNKsj';
  error?: Maybe<IncreasePayoutAmountObjectCGxqNKsjErrorObject>;
  object?: Maybe<IncreasePayoutAmountObject>;
};

export type IncreasePayoutAmountObjectCGxqNKsjErrorObject = {
  __typename?: 'IncreasePayoutAmountObjectCGxqNKsjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type InsertTagInput = {
  tagId: Scalars['String'];
  userId: Scalars['String'];
};

export type IntResponseObject = {
  __typename?: 'IntResponseObject';
  error?: Maybe<IntegerResponseObjectErrorObject>;
  object?: Maybe<Scalars['Int']>;
};

export type IntegerResponseObjectErrorObject = {
  __typename?: 'IntegerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IsEmailRegisteredInput = {
  email: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  redirectionUrl?: InputMaybe<Scalars['String']>;
};

export type IsEmailRegisteredSsoInput = {
  googleToken: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type IsUserEmailRegisteredObject = {
  __typename?: 'IsUserEmailRegisteredObject';
  fundraiserCreationToken?: Maybe<Scalars['String']>;
  hadPasswordInSimplykV1: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasResetPasswordToken: Scalars['Boolean'];
  shouldAuthenticateWithGoogle?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userRegistrationStatus: UserRegistrationStatus;
};

export type IsUserEmailRegisteredResponseObject = {
  __typename?: 'IsUserEmailRegisteredResponseObject';
  error?: Maybe<IsUserEmailRegisteredResponseObjectErrorObject>;
  object?: Maybe<IsUserEmailRegisteredObject>;
};

export type IsUserEmailRegisteredResponseObjectErrorObject = {
  __typename?: 'IsUserEmailRegisteredResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IssuingCardObject = {
  __typename?: 'IssuingCardObject';
  cardHolderStatus: CardHolderStatus;
  id: Scalars['String'];
  isCardHolder: Scalars['Boolean'];
  last4?: Maybe<Scalars['String']>;
  status: IssuingCardStatus;
  stripeIssuingCardId?: Maybe<Scalars['String']>;
  treasuryFinancialAccountId?: Maybe<Scalars['String']>;
};

export enum IssuingCardStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type LatLngBoundariesInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type ListTransactionTicketsObject = {
  __typename?: 'ListTransactionTicketsObject';
  allCanceled: Scalars['Boolean'];
  productTickets: Array<ProductTicketObject>;
};

export type ListTransactionTicketsObjectErrorObject = {
  __typename?: 'ListTransactionTicketsObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ListTransactionTicketsResponse = {
  __typename?: 'ListTransactionTicketsResponse';
  error?: Maybe<ListTransactionTicketsObjectErrorObject>;
  object?: Maybe<ListTransactionTicketsObject>;
};

export enum Locales {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type ManualContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MarkerHoverDetailsObject = {
  __typename?: 'MarkerHoverDetailsObject';
  color?: Maybe<Scalars['String']>;
  cursorType: CursorType;
  dates: Array<EventDateRange>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MarkerPositionObject = {
  __typename?: 'MarkerPositionObject';
  coordinates: CoordinatesObject;
  cursorType: CursorType;
  id: Scalars['String'];
};

export type MarkerPositionResponseObjectErrorObject = {
  __typename?: 'MarkerPositionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum MembershipValidityPeriod {
  AtDate = 'AtDate',
  NoExpiration = 'NoExpiration',
  OneMonthAfterPayment = 'OneMonthAfterPayment',
  OneYearAfterPayment = 'OneYearAfterPayment'
}

export enum MergeUsersError {
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  UserNotFound = 'UserNotFound'
}

export type MergeUsersObject = {
  __typename?: 'MergeUsersObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type MergeUsersObjectt0lIBshR = {
  __typename?: 'MergeUsersObjectt0lIBshR';
  error?: Maybe<MergeUsersObjectt0lIBshRErrorObject>;
  object?: Maybe<MergeUsersObject>;
};

export type MergeUsersObjectt0lIBshRErrorObject = {
  __typename?: 'MergeUsersObjectt0lIBshRErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectLabelObject = {
  __typename?: 'MultiselectLabelObject';
  label: Scalars['String'];
  locale: Locales;
};

export type MultiselectOccurrenceValueObject = {
  __typename?: 'MultiselectOccurrenceValueObject';
  endUtc: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  value: Scalars['String'];
};

export type MultiselectOccurrenceValueObjectSoVvz9E7 = {
  __typename?: 'MultiselectOccurrenceValueObjectSoVVZ9E7';
  error?: Maybe<MultiselectOccurrenceValueObjectSoVvz9E7ErrorObject>;
  items?: Maybe<Array<MultiselectOccurrenceValueObject>>;
};

export type MultiselectOccurrenceValueObjectSoVvz9E7ErrorObject = {
  __typename?: 'MultiselectOccurrenceValueObjectSoVVZ9E7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObject = {
  __typename?: 'MultiselectValueObject';
  labels: Array<MultiselectLabelObject>;
  value: Scalars['String'];
};

export type MultiselectValueObjectQQmQdcEq = {
  __typename?: 'MultiselectValueObjectQQmQDCEq';
  error?: Maybe<MultiselectValueObjectQQmQdcEqErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectQQmQdcEqErrorObject = {
  __typename?: 'MultiselectValueObjectQQmQDCEqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObjectuwbVckTm = {
  __typename?: 'MultiselectValueObjectuwbVckTM';
  error?: Maybe<MultiselectValueObjectuwbVckTmErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectuwbVckTmErrorObject = {
  __typename?: 'MultiselectValueObjectuwbVckTMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueResponseListObject = {
  __typename?: 'MultiselectValueResponseListObject';
  error?: Maybe<MultiselectValueResponseListObjectErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueResponseListObjectErrorObject = {
  __typename?: 'MultiselectValueResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCardIssuing: BooleanzqV4Fcfl;
  activateDonationForm: DonationFormObjectnh7Fzc1c;
  activateTicketing: TicketingObjectQenK0Vcb;
  activeTwoFactorAuth: BooleanfbGeQt0P;
  addEngagementStepChecked: BooleanJanf8ulG;
  addManualContact: StringW81iFstI;
  addRetroActiveReferral: BooleanQeUj9seU;
  addTicketingOffset: BooleanhNipxHrM;
  archiveCampaign: NumberTv4npItn;
  archiveDonationForm: BooleanrFloHhz2;
  archiveTeam: BooleanmtlJumqG;
  archiveTicketing: Boolean1TqHKyWm;
  cancelCommandSubscription: CommandObjectjGnNjvnv;
  cancelDonorCommandSubscription: CommandObjectXrg6nVs6;
  cancelReceipt: ReceiptObjectVoeDpBfe;
  capturePaymentIntent: BooleanResponseObject;
  changeContactEmailSubscription: Booleanv1S4Cnlu;
  checkinProductTicket: BooleaniwYvw2kF;
  checkinProductTicketByCommandId: BooleanXNzB24Qc;
  closeTicketing: BooleanTd3RcY9p;
  confirmPaymentIntent: CommandObjectKswBq7mL;
  createBalanceTransferToIssuing: BooleanzV5gvTxi;
  createDonationFormFromTemplate: StringShzGjcKz;
  createDonorFormTranslations: DonationFormObjectMmhmFsh4;
  createDraftCampaign: DonationFormObjectgAVgLBt5;
  createDraftDonationForm: DonationFormObjectxYXd0OUg;
  createDraftTicketing: Stringixfcs8E6;
  createFormTranslations: DonationFormObjectz1Xlj2El;
  createFrontendDonor: UserResponseObject;
  createFundraiserFormTranslations: DonationFormObject4Vdoi5ad;
  createIssuingPayout: CreatedIssuingPayoutObject3YzGqYqO;
  createIssuingTopup: Booleanm9VuYbJo;
  createNewOrganization: AccessTokenObject6iTx7R8d;
  createNote: NoteResponseObject;
  createPlaidLinkToken: String51Mbgxim;
  createPreviewReceipt: StringLEwOod15;
  createReceipt: ReceiptResponseObject;
  createSampleContact: ContactObjectakOhRwy8;
  createSampleTransaction: TransactionObjectJrMafMa9;
  createStripeCheckoutSession: CheckoutSessionResponseObject;
  createStripeCustomExternalAccount: ExternalAccountObjectWuGh1tvy;
  createTags: StringListResponseObject;
  createTicketingCardPaymentIntent: PaymentIntentResponseObject;
  deactivateOrganization: BooleanResponseObject;
  deactiveTwoFactorAuth: BooleaneH1Ubzyn;
  deleteCampaign: Number8XfH57Ki;
  deleteContact: BooleanResponseObject;
  deleteDonationForm: BooleanCq7JviqG;
  deleteEmailTemplate: EmailTemplateResponseObject;
  deleteMemberToOrganizationAdministrators: UserObjectii9HEbYl;
  deleteNote?: Maybe<StringResponseObject>;
  deleteSampleContact: Booleanjxm6yt31;
  deleteSampleTransaction: BooleanrNHkps10;
  deleteTagForAllUsers: BooleanResponseObject;
  deleteTags: BooleanResponseObject;
  deleteTeam: BooleanT5jvAixT;
  deleteTicketing: Booleanx0foq5fL;
  deleteUser: Boolean2A9hIodo;
  disableGoogleSsoLogin: BooleanX5FbSsRy;
  dismissActivationPayoutSuccessCard: BooleanK7Cf1Jja;
  displayEngagementChecklist: Booleaneme5agGx;
  editAnswer: AnswerResponseObject;
  editCommandSubscriptionAmount: CommandResponseObject;
  editContact: ContactResponseObject;
  editContactEmail: UserObjectkh6IWbxH;
  editDonationCommandForm: CommandResponseObject;
  editDonorCommandSubscriptionAmount: CommandResponseObject;
  editDonorFormAdvancedParameters: DonationFormObjectmehoCDoT;
  editDonorFormCustomization: DonationFormObjectWNvj0Abb;
  editDonorFormGeneralInformations: DonationFormObjectDj51G6jA;
  editFormAdvancedParameters: DonationFormObjectZaLflz7f;
  editFormCustomization: DonationFormObjectOrZbBzPi;
  editFormGeneralInformations: DonationFormObjectkWWwSv3s;
  editFundraiserFormAdvancedParameters: DonationFormObjectWUzoYc9m;
  editFundraiserFormCustomization: DonationFormObjectLWa0y6eX;
  editFundraiserFormGeneralInformations: DonationFormObject7MHfe0A9;
  editNote?: Maybe<NoteResponseObject>;
  editPassword: UserObjectBmPrqPqo;
  editTag: BooleanResponseObject;
  editTicketingAdvancedParameters: TicketingObjectFi86yTxf;
  editTicketingEventCustomization: TicketingObjectEyBqyLcD;
  editTicketingGeneralInformation: TicketingObject3U5jLv13;
  editTicketingRates: TicketingObjectdjo3Gl0m;
  editTicketingReminderData: TicketingObjectJcp7Owcl;
  editUnsubscribePreferences: BooleanIpPgKEi5;
  exportBalanceTransactionsTable: ExportResponseObject;
  exportDonorReceiptTable: ExportResponseObject;
  exportFundraiserList: ExportObjectB3Cs6kOb;
  exportPayoutsTable: ExportResponseObject;
  exportTransactionsTable: ExportResponseObject;
  generatePreviewETicket: StringResponseObject;
  getCannyToken: StringResponseObject;
  getDiscountCodeFromTicketingIdAndBody: DiscountResponseObject;
  increasePayoutAmount: IncreasePayoutAmountObjectCGxqNKsj;
  insertTags: BooleanResponseObject;
  isUserEmailRegistered: IsUserEmailRegisteredResponseObject;
  isUserEmailRegisteredSSO: AccessTokenObjectIHxJUctK;
  launchImport: ImportUsersGoogleSheetObjectjOdKwQcI;
  mergeTeams: Boolean9gk3Brhx;
  mergeUsers: MergeUsersObjectt0lIBshR;
  moveParticipantToCampaign: BooleansZJvO8fX;
  openTicketing: TicketingObjectTvYoVw0l;
  postSubmitCommand: PostSubmitCommandObject7tKq4ltS;
  processIssuingRewards: BooleanxcoarZkm;
  processPayout: BooleanByLwu2oi;
  purgeAllCache: BooleanRbsdGwy7;
  reOpenCommand: BooleangbIfPpOk;
  refundTip: TransactionObject4siFytBa;
  refundTransaction: RefundResponseObject;
  removeEngagementChecklist: BooleanVIqrMBmb;
  requestIssuingCard: Boolean8Q7uvOvP;
  resendTransactionReceipt?: Maybe<StringResponseObject>;
  resendTwoFactorCode: BooleanwV4RxFba;
  resetEngagementStepChecked: BooleanHyNnQAfW;
  resetPassword: AccessTokenObject3RLbgMeK;
  reverseDonationAnonymous: BooleanF8d9NlEc;
  saveCardOrder: CreateOrderResponseObject;
  saveDonationThankYouComment: CommandResponseObject;
  saveV1MobileCommand: CreateMobileCommandResponseObject;
  scanTicket: ProductTicketObjectctrzUrJu;
  sendMergeUserRequest: SendMergeUsersRequestResponseObject;
  sendMonthlyBalanceTransactionExport: BooleanVUe9TqDz;
  sendResetPasswordRequest: ResetPasswordLinkResponseObject;
  sendResetPasswordRequestMobile: ResetPasswordLinkResponseObject;
  sendTransactionReceiptForExistingCommand: CommandResponseObject;
  sendTwoFactorCode: BooleanUnUMtdgo;
  sendVerifyEmailNotification: BooleanlRh8wcbp;
  setHasDismissedEmailInfobox: BooleannjE8QqLj;
  setHasDismissedEmailTemplatePrompt: BooleanLPthXe5V;
  setHasDismissedReferralPrompt: Booleang5fHfuys;
  setHasSeenReferralPage: BooleanResponseObject;
  setUserAsOnboardedOnCheckinManager: Booleanv4WUg746;
  setUserCurrentOrganization: AccessTokenObjectLHnSTslJ;
  setUserCurrentOrganizationMobile: AccessTokenObject;
  setVolumeThresholdReached: OrganizationResponseObject;
  signIn: AccessTokenObjectuqYta8gA;
  signInAsAdministrator: AccessTokenObjectFtwN8AOf;
  signInMobile?: Maybe<AccessTokenObject>;
  signInMobileWithGoogle?: Maybe<AccessTokenObject>;
  signOut: BooleanCn0Ul7Sa;
  signUp: AccessTokenObjectWxLidYsF;
  switchOrgAsAdministrator: AccessTokenResponseObject;
  testModeAutomateOnboardingToStripe: BooleanResponseObject;
  trackDownload?: Maybe<Scalars['Boolean']>;
  triggerManualCron: BooleanieLmrpgl;
  unarchiveCampaign: Number4VOssO2b;
  unarchiveDonationForm: BooleanbHBaKpgj;
  unarchiveTicketing: BooleanY2gchYJe;
  unsubscribeUserFromEmails: Booleanb2p8zEeg;
  updateAccessTokenWithTargetedOrganization: BooleanEntuKoon;
  updateAdministrativeInformation: OrganizationObjectOjXw1n2K;
  updateAdministrativeMoreInformation: OrganizationObjectquOj8Led;
  updateAmountsFromToken: StringmlsZbkcs;
  updateCompanyOnboardingQualification: BooleanResponseObject;
  updateHubspotContactProperty: Boolean7T3CeE3m;
  updateOrganizationCategory: BooleanResponseObject;
  updateOrganizationEngagementFlags: BooleanResponseObject;
  updateOrganizationFlags: Boolean00gymykM;
  updateOrganizationHubspotProperty: BooleanResponseObject;
  updateOrganizationOnboardedFormCategory: BooleanF1sEl1E3;
  updateOrganizationWithStripeCustomFlowHasStarted: BooleanEz5saxNi;
  updatePaymentIntent: UpdatePaymentIntentResponse;
  updatePayoutInterval: StripeAccountResponseObject;
  updateProductDonationAnnotation: BooleanqNph0bMw;
  updateProductDonationAnonymous: BooleanZ0Dur8qv;
  updateProductTicketAnnotation: BooleanResponseObject;
  uploadChunkForImport: ChunkImportObjectTHhB8buY;
  upsertSession: SessionResponseObject;
  verifyEmail: VerifyEmailObjectmrUmb6Vg;
  verifyTwoFactorCode: BooleancS2v2Uzb;
};


export type MutationActivateDonationFormArgs = {
  activateDonationFormInput: ActivateDonationFormInput;
};


export type MutationActivateTicketingArgs = {
  activateTicketingInput: ActivateTicketingInput;
};


export type MutationAddEngagementStepCheckedArgs = {
  activationStepId: Scalars['String'];
};


export type MutationAddManualContactArgs = {
  manualContactInput: ManualContactInput;
};


export type MutationAddRetroActiveReferralArgs = {
  retroActiveReferralInput: RetroActiveReferralInput;
};


export type MutationAddTicketingOffsetArgs = {
  offset: Scalars['Int'];
  ticketingIdOrPath: Scalars['String'];
};


export type MutationArchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationArchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationArchiveTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationArchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationCancelCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelDonorCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  receiptId: Scalars['String'];
};


export type MutationCapturePaymentIntentArgs = {
  paymentIntentId: Scalars['String'];
};


export type MutationChangeContactEmailSubscriptionArgs = {
  unsubscribe: Scalars['Boolean'];
  userEmails: Array<Scalars['String']>;
};


export type MutationCheckinProductTicketArgs = {
  isCheckin: Scalars['Boolean'];
  productTicketId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckinProductTicketByCommandIdArgs = {
  commandId: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationCloseTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput: ConfirmPaymentIntentInput;
};


export type MutationCreateBalanceTransferToIssuingArgs = {
  input: ProcessBalanceTransferInput;
};


export type MutationCreateDonationFormFromTemplateArgs = {
  template: DonationFormTemplateType;
};


export type MutationCreateDonorFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateDraftCampaignArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftDonationFormArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftTicketingArgs = {
  ticketing: CreateTicketingInput;
};


export type MutationCreateFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateFrontendDonorArgs = {
  donor: DonorInput;
};


export type MutationCreateFundraiserFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
  token: Scalars['String'];
};


export type MutationCreateIssuingPayoutArgs = {
  input: CreatedIssuingPayoutInput;
};


export type MutationCreateIssuingTopupArgs = {
  input: CreateIssuingTopupInput;
};


export type MutationCreateNewOrganizationArgs = {
  newOrganizationInput: CreateNewOrganizationInput;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreatePreviewReceiptArgs = {
  previewReceiptParams: PreviewReceiptParams;
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};


export type MutationCreateStripeCustomExternalAccountArgs = {
  externalAccountInput: ExternalAccountInput;
};


export type MutationCreateTagsArgs = {
  tag: CreateTagInput;
};


export type MutationCreateTicketingCardPaymentIntentArgs = {
  paymentIntent: TicketingPaymentIntentInput;
};


export type MutationDeactivateOrganizationArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactiveTwoFactorAuthArgs = {
  param: DeactiveTwoFactorAuthInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDonationFormArgs = {
  donationFormId: Scalars['String'];
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberToOrganizationAdministratorsArgs = {
  email: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteSampleContactArgs = {
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSampleTransactionArgs = {
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTagForAllUsersArgs = {
  tagId: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationDeleteTicketingArgs = {
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
  ticketingId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserAccountInput;
};


export type MutationDisableGoogleSsoLoginArgs = {
  userEmail: Scalars['String'];
};


export type MutationEditAnswerArgs = {
  answerInput: AnswerInput;
};


export type MutationEditCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditContactArgs = {
  editContactInput: EditContactInput;
  userId: Scalars['String'];
};


export type MutationEditContactEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditDonationCommandFormArgs = {
  donationFormId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEditDonorCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditDonorFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFundraiserFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditPasswordArgs = {
  editPasswordInput: EditPasswordInput;
};


export type MutationEditTagArgs = {
  tag: EditTagInput;
};


export type MutationEditTicketingAdvancedParametersArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingEventCustomizationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingGeneralInformationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingRatesArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingReminderDataArgs = {
  data: EditReminderDataInput;
};


export type MutationEditUnsubscribePreferencesArgs = {
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
};


export type MutationExportBalanceTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<BalanceTransactionExportFilter>;
};


export type MutationExportDonorReceiptTableArgs = {
  exportParameters: ExportParametersInput;
  year: Scalars['Float'];
};


export type MutationExportFundraiserListArgs = {
  donationFormId: Scalars['String'];
  exportParameters: ExportParametersInput;
};


export type MutationExportPayoutsTableArgs = {
  exportParameters: ExportParametersInput;
};


export type MutationExportTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<TransactionExportFilter>;
};


export type MutationGeneratePreviewETicketArgs = {
  ticketPreviewParams: PreviewTicketParams;
};


export type MutationGetDiscountCodeFromTicketingIdAndBodyArgs = {
  discountCodeBody: Scalars['String'];
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type MutationIncreasePayoutAmountArgs = {
  input: IncreasePayoutAmountInput;
};


export type MutationInsertTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationIsUserEmailRegisteredArgs = {
  isEmailRegisteredInput: IsEmailRegisteredInput;
};


export type MutationIsUserEmailRegisteredSsoArgs = {
  isEmailRegisteredSSOInput: IsEmailRegisteredSsoInput;
};


export type MutationLaunchImportArgs = {
  base64: Scalars['String'];
  rowNum: Scalars['Float'];
  type: ImportType;
};


export type MutationMergeTeamsArgs = {
  teamToBeMergedIdOrPath: Scalars['String'];
  teamToMergeInIdOrPath: Scalars['String'];
};


export type MutationMergeUsersArgs = {
  encryptedToken: Scalars['String'];
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationMoveParticipantToCampaignArgs = {
  participantIdOrPath: Scalars['String'];
  targetedTeamIdOrPath: Scalars['String'];
};


export type MutationOpenTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationPostSubmitCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationProcessPayoutArgs = {
  payoutId: Scalars['String'];
};


export type MutationReOpenCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationRefundTipArgs = {
  amountToRefund: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  amountToRefund: Scalars['Float'];
  cancelTaxReceipt: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRequestIssuingCardArgs = {
  cardHolderInput: CreateCardHolderInput;
};


export type MutationResendTransactionReceiptArgs = {
  email: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationResendTwoFactorCodeArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationReverseDonationAnonymousArgs = {
  donationFormIdOrPath: Scalars['String'];
  donorEmail: Scalars['String'];
};


export type MutationSaveCardOrderArgs = {
  editSessionInput: EditSessionOnSubmission;
  order: CreateOrderInput;
  ticketingId: Scalars['String'];
  tickets: Array<CreateTicketInput>;
};


export type MutationSaveDonationThankYouCommentArgs = {
  commandId: Scalars['String'];
  thankYouComment: Scalars['String'];
};


export type MutationSaveV1MobileCommandArgs = {
  createMobileCommandInput: CreateMobileCommandInput;
};


export type MutationScanTicketArgs = {
  ticketIdInput: UuidInput;
};


export type MutationSendMergeUserRequestArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationSendResetPasswordRequestArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendResetPasswordRequestMobileArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendTransactionReceiptForExistingCommandArgs = {
  commandId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendVerifyEmailNotificationArgs = {
  redirectionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSetUserCurrentOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetUserCurrentOrganizationMobileArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetVolumeThresholdReachedArgs = {
  volumeThresholdReachedInput: UpdateVolumeThresholdReached;
};


export type MutationSignInArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationSignInAsAdministratorArgs = {
  signInAsAdministratorInput: SignInAsAdministratorInput;
};


export type MutationSignInMobileArgs = {
  signInInput: SignInInput;
};


export type MutationSignInMobileWithGoogleArgs = {
  googleToken: Scalars['String'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationSwitchOrgAsAdministratorArgs = {
  switchOrgAsAdministratorInput: SwitchOrgAsAdministratorInput;
};


export type MutationTrackDownloadArgs = {
  location: Scalars['String'];
};


export type MutationTriggerManualCronArgs = {
  cronName: CronQueues;
  delay?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUnarchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationUnarchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationUnarchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationUnsubscribeUserFromEmailsArgs = {
  organizationId: Scalars['String'];
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateAccessTokenWithTargetedOrganizationArgs = {
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdministrativeInformationArgs = {
  updateAdministrativeInformationInput: UpdateAdministrativeInformationInput;
};


export type MutationUpdateAdministrativeMoreInformationArgs = {
  updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput;
};


export type MutationUpdateAmountsFromTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCompanyOnboardingQualificationArgs = {
  qualificationInput: OnboardingQualificationInput;
};


export type MutationUpdateHubspotContactPropertyArgs = {
  payload: HubspotProperty;
};


export type MutationUpdateOrganizationCategoryArgs = {
  category: OrganizationCategory;
};


export type MutationUpdateOrganizationEngagementFlagsArgs = {
  updateOrganizationEngagementFlagsInput: UpdateOrganizationEngagementFlagsInput;
};


export type MutationUpdateOrganizationFlagsArgs = {
  input: OrganizationFlagsInput;
};


export type MutationUpdateOrganizationHubspotPropertyArgs = {
  input: UpdateOrganizationHubspotPropertyInput;
};


export type MutationUpdateOrganizationOnboardedFormCategoryArgs = {
  onboardedFormCategory: FormCategory;
};


export type MutationUpdatePaymentIntentArgs = {
  params: UpdatePaymentIntentInput;
};


export type MutationUpdatePayoutIntervalArgs = {
  updatePayoutInterval: UpdatePayoutInterval;
};


export type MutationUpdateProductDonationAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productDonationId: Scalars['String'];
};


export type MutationUpdateProductDonationAnonymousArgs = {
  productDonationId: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductTicketAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productTicketId: Scalars['String'];
};


export type MutationUploadChunkForImportArgs = {
  chunk: Scalars['String'];
  chunkIndex: Scalars['Float'];
  importId?: InputMaybe<Scalars['String']>;
  isLastChunk: Scalars['Boolean'];
  type: ImportType;
};


export type MutationUpsertSessionArgs = {
  sessionInput: UpsertSessionInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};


export type MutationVerifyTwoFactorCodeArgs = {
  value: Scalars['String'];
};

export type MyReferralOrganizationObject = {
  __typename?: 'MyReferralOrganizationObject';
  count: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  isStripeCustomAccountActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MyReferralOrganizationObjectglavFoyq = {
  __typename?: 'MyReferralOrganizationObjectglavFOYQ';
  error?: Maybe<MyReferralOrganizationObjectglavFoyqErrorObject>;
  items?: Maybe<Array<MyReferralOrganizationObject>>;
};

export type MyReferralOrganizationObjectglavFoyqErrorObject = {
  __typename?: 'MyReferralOrganizationObjectglavFOYQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MyReferrerOrganizationObject = {
  __typename?: 'MyReferrerOrganizationObject';
  moneyGathered: Scalars['Float'];
  organization: OrganizationObject;
};

export type MyReferrerOrganizationResponseObject = {
  __typename?: 'MyReferrerOrganizationResponseObject';
  error?: Maybe<MyReferrerOrganizationResponseObjectErrorObject>;
  object?: Maybe<MyReferrerOrganizationObject>;
};

export type MyReferrerOrganizationResponseObjectErrorObject = {
  __typename?: 'MyReferrerOrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NewNonprofitObject = {
  __typename?: 'NewNonprofitObject';
  city?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  einBn: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewNonprofitObject9NbwQpHk = {
  __typename?: 'NewNonprofitObject9NbwQPHk';
  error?: Maybe<NewNonprofitObject9NbwQpHkErrorObject>;
  items?: Maybe<Array<NewNonprofitObject>>;
};

export type NewNonprofitObject9NbwQpHkErrorObject = {
  __typename?: 'NewNonprofitObject9NbwQPHkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum NewSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Created = 'Created',
  PastDue = 'PastDue'
}

export type NextActionObject = {
  __typename?: 'NextActionObject';
  paymentIntentSecret?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<RedirectToUrlObject>;
  type?: Maybe<NextActionType>;
};

export enum NextActionType {
  OpenAchModal = 'OpenAchModal',
  OpenPadModal = 'OpenPadModal',
  ThreeDSecureRedirect = 'ThreeDSecureRedirect'
}

export type NoteObject = {
  __typename?: 'NoteObject';
  contactId: Scalars['String'];
  content: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  writer?: Maybe<UserObject>;
  writerId: Scalars['String'];
};

export type NoteResponseListObject = {
  __typename?: 'NoteResponseListObject';
  error?: Maybe<NoteResponseListObjectErrorObject>;
  items?: Maybe<Array<NoteObject>>;
};

export type NoteResponseListObjectErrorObject = {
  __typename?: 'NoteResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NoteResponseObject = {
  __typename?: 'NoteResponseObject';
  error?: Maybe<NoteResponseObjectErrorObject>;
  object?: Maybe<NoteObject>;
};

export type NoteResponseObjectErrorObject = {
  __typename?: 'NoteResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number4VOssO2b = {
  __typename?: 'Number4VOssO2b';
  error?: Maybe<Number4VOssO2bErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number4VOssO2bErrorObject = {
  __typename?: 'Number4VOssO2bErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number8XfH57Ki = {
  __typename?: 'Number8XfH57KI';
  error?: Maybe<Number8XfH57KiErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number8XfH57KiErrorObject = {
  __typename?: 'Number8XfH57KIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberAgSakcpm = {
  __typename?: 'NumberAgSakcpm';
  error?: Maybe<NumberAgSakcpmErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberAgSakcpmErrorObject = {
  __typename?: 'NumberAgSakcpmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObject = {
  __typename?: 'NumberResponseObject';
  error?: Maybe<NumberResponseObjectErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberResponseObjectErrorObject = {
  __typename?: 'NumberResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObjects85inbsb = {
  __typename?: 'NumberResponseObjects85inbsb';
  error?: Maybe<NumberResponseObjects85inbsbErrorObject>;
  object?: Maybe<NumberResponseObject>;
};

export type NumberResponseObjects85inbsbErrorObject = {
  __typename?: 'NumberResponseObjects85inbsbErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberTv4npItn = {
  __typename?: 'NumberTv4npItn';
  error?: Maybe<NumberTv4npItnErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberTv4npItnErrorObject = {
  __typename?: 'NumberTv4npItnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Numberee2YrrX3 = {
  __typename?: 'Numberee2YrrX3';
  error?: Maybe<Numberee2YrrX3ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Numberee2YrrX3ErrorObject = {
  __typename?: 'Numberee2YrrX3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesListResponseObjectErrorObject = {
  __typename?: 'OccurrenceWithRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesObject = {
  __typename?: 'OccurrenceWithRatesObject';
  occurrence?: Maybe<TicketingOccurrenceObject>;
  rates: Array<RateByOccurrenceObject>;
};

export enum OnboardingQualification {
  Large = 'Large',
  Medium = 'Medium',
  Other = 'Other',
  Small = 'Small'
}

export type OnboardingQualificationInput = {
  onboardingQualification: OnboardingQualification;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  createdCampaigns?: Maybe<CreatedCampaignsObject>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountObject>;
  discountId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraDonation?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastTransactionId?: Maybe<Scalars['String']>;
  locale: Locales;
  occurrence?: Maybe<TicketingOccurrenceObject>;
  occurrenceId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  ticketing: TicketingObject;
  ticketingId: Scalars['String'];
  tickets?: Maybe<Array<TicketObject>>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderResponseObjectErrorObject = {
  __typename?: 'OrderResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationAndOwnerObject = {
  __typename?: 'OrganizationAndOwnerObject';
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationAndOwnerObjectTd09oH9F = {
  __typename?: 'OrganizationAndOwnerObjectTD09oH9F';
  error?: Maybe<OrganizationAndOwnerObjectTd09oH9FErrorObject>;
  items?: Maybe<Array<OrganizationAndOwnerObject>>;
};

export type OrganizationAndOwnerObjectTd09oH9FErrorObject = {
  __typename?: 'OrganizationAndOwnerObjectTD09oH9FErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationCategory {
  AnimalWelfare = 'AnimalWelfare',
  CommunityService = 'CommunityService',
  Culture = 'Culture',
  Education = 'Education',
  Environment = 'Environment',
  Health = 'Health',
  Humanitarian = 'Humanitarian',
  Other = 'Other',
  Politic = 'Politic',
  Religion = 'Religion',
  SocialService = 'SocialService',
  SportsAndLeisure = 'SportsAndLeisure',
  StudentGroup = 'StudentGroup',
  Veterans = 'Veterans'
}

export enum OrganizationCause {
  Education = 'Education',
  Nature = 'Nature',
  Solidarity = 'Solidarity',
  SportAndCulture = 'SportAndCulture'
}

export enum OrganizationCountry {
  Canada = 'Canada',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates'
}

export type OrganizationDetailsObject = {
  __typename?: 'OrganizationDetailsObject';
  closingDate?: Maybe<Scalars['Date']>;
  fundsRaised: Scalars['Float'];
  numberContacts: Scalars['Float'];
  numberForms: Scalars['Float'];
  numberOfReferrals: Scalars['Float'];
  numberPayments: Scalars['Float'];
  numberUserAccounts: Scalars['Float'];
};

export type OrganizationEngagementFlagsObject = {
  __typename?: 'OrganizationEngagementFlagsObject';
  engagementAddContactTour: Scalars['Boolean'];
  engagementContactNotesTour: Scalars['Boolean'];
  engagementContactPaymentsTour: Scalars['Boolean'];
  engagementContactTagsTour: Scalars['Boolean'];
  engagementContactsBulkTour: Scalars['Boolean'];
  engagementEmailRecipientsTour: Scalars['Boolean'];
  engagementEmailStatsTour: Scalars['Boolean'];
  engagementEmailTemplatesTour: Scalars['Boolean'];
  engagementPaymentDetailsTour: Scalars['Boolean'];
  engagementPaymentTour: Scalars['Boolean'];
  engagementPaymentsExportTour: Scalars['Boolean'];
  id: Scalars['String'];
};

export type OrganizationEngagementFlagsObjectgOImoy0l = {
  __typename?: 'OrganizationEngagementFlagsObjectgOImoy0l';
  error?: Maybe<OrganizationEngagementFlagsObjectgOImoy0lErrorObject>;
  object?: Maybe<OrganizationEngagementFlagsObject>;
};

export type OrganizationEngagementFlagsObjectgOImoy0lErrorObject = {
  __typename?: 'OrganizationEngagementFlagsObjectgOImoy0lErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationFlagsInput = {
  demotivateEmbeds?: InputMaybe<AmplitudeVariantValue>;
};

export type OrganizationGroupObject = {
  __typename?: 'OrganizationGroupObject';
  id: Scalars['String'];
  organization: OrganizationObject;
  organizationId: Scalars['String'];
  permissions: Array<UserPermissions>;
  role: UserRoles;
  user: UserObject;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  activationChecklistExperiment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  badTipperHubspotFlag?: Maybe<Scalars['Boolean']>;
  balanceTransferDelayDay: Scalars['Float'];
  bankDescriptor?: Maybe<Scalars['String']>;
  blockPayment: Scalars['Boolean'];
  canGenerateReceipt: Scalars['Boolean'];
  cardMaximumAmount?: Maybe<Scalars['Int']>;
  category?: Maybe<OrganizationCategory>;
  cause?: Maybe<OrganizationCause>;
  charityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  createdAtUtc?: Maybe<Scalars['Date']>;
  demotivateEmbeds?: Maybe<AmplitudeVariantValue>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  dismissOnboardingOnFormPageAt?: Maybe<Scalars['Date']>;
  engagementCheckListStepChecked?: Maybe<Array<Scalars['String']>>;
  engagementChecklistExperiment?: Maybe<AmplitudeVariantValue>;
  featureIssuingEnabled: Scalars['Boolean'];
  featureTreasuryEnabled: Scalars['Boolean'];
  firstPaymentDate?: Maybe<Scalars['Date']>;
  fourthPaymentDate?: Maybe<Scalars['Date']>;
  fraudulent: Scalars['Boolean'];
  fraudulentAtUtc?: Maybe<Scalars['Date']>;
  hasAdministrativeInformation: Scalars['Boolean'];
  hasAtLeastOnePayment?: Maybe<Scalars['Boolean']>;
  hasCharityNumber: Scalars['Boolean'];
  hasDismissActivationPayoutSuccessCard?: Maybe<Scalars['Boolean']>;
  hasFinishZeffyOnboarding: Scalars['Boolean'];
  hasImportGmailContacts?: Maybe<Scalars['Boolean']>;
  hasImportMailchimpContacts?: Maybe<Scalars['Boolean']>;
  hasStripeCustomFlowStarted: Scalars['Boolean'];
  hasVisitorReports?: Maybe<Scalars['Boolean']>;
  hideFromZeffyMap: Scalars['Boolean'];
  hubspotNpoStarterDealId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPaymentDisabled?: Maybe<Scalars['Boolean']>;
  isPoBoxAddress?: Maybe<Scalars['Boolean']>;
  isStripeAccountDeprecated?: Maybe<Scalars['Boolean']>;
  isStripeCustomAccountActive: Scalars['Boolean'];
  lastPaymentDate?: Maybe<Scalars['Date']>;
  legalName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npoStarterStateCertificateUrl?: Maybe<Scalars['String']>;
  npoStarterToDoListStepChecked?: Maybe<Array<Scalars['String']>>;
  onboardedFormCategory?: Maybe<FormCategory>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  owner?: Maybe<UserObject>;
  ownerEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  previewFirstFormAt?: Maybe<Scalars['Date']>;
  propublicaCCode?: Maybe<Scalars['Float']>;
  referralAmountLimit: Scalars['Float'];
  referralQuestion?: Maybe<ReferralQuestionChoice>;
  referralQuestionOther?: Maybe<Scalars['String']>;
  referredByOrganizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  stripeCustomAccountActiveAtUtc?: Maybe<Scalars['Date']>;
  stripeCustomAccountId?: Maybe<Scalars['String']>;
  stripeTopupSourceId?: Maybe<Scalars['String']>;
  stripeUserId?: Maybe<Scalars['String']>;
  stripeVerificationSucceededBannerShown?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagObject>>;
  type?: Maybe<OrganizationType>;
  volumeThresholdReached?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationObjectOjXw1n2K = {
  __typename?: 'OrganizationObjectOjXw1n2K';
  error?: Maybe<OrganizationObjectOjXw1n2KErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectOjXw1n2KErrorObject = {
  __typename?: 'OrganizationObjectOjXw1n2KErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationObjectquOj8Led = {
  __typename?: 'OrganizationObjectquOj8LED';
  error?: Maybe<OrganizationObjectquOj8LedErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectquOj8LedErrorObject = {
  __typename?: 'OrganizationObjectquOj8LEDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicObject = {
  __typename?: 'OrganizationPublicObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<OwnerObject>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationPublicResponseListObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicResponseObject = {
  __typename?: 'OrganizationPublicResponseObject';
  error?: Maybe<OrganizationPublicResponseObjectErrorObject>;
  object?: Maybe<OrganizationPublicObject>;
};

export type OrganizationPublicResponseObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseListObjectErrorObject = {
  __typename?: 'OrganizationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseObject = {
  __typename?: 'OrganizationResponseObject';
  error?: Maybe<OrganizationResponseObjectErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationResponseObjectErrorObject = {
  __typename?: 'OrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationType {
  Charity = 'Charity',
  Other = 'Other',
  Other501C = 'Other501C',
  Private = 'Private',
  Social = 'Social'
}

export type OverTimeFilterInput = {
  slot: OverTimeSlot;
};

export enum OverTimeSlot {
  fourteenDays = 'fourteenDays',
  oneMonth = 'oneMonth',
  sevenDays = 'sevenDays',
  seventyTwoHours = 'seventyTwoHours',
  twentyFourHours = 'twentyFourHours',
  twoHours = 'twoHours'
}

export type OwnerObject = {
  __typename?: 'OwnerObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PageViewsSummaryItemObject = {
  __typename?: 'PageViewsSummaryItemObject';
  count: Scalars['Float'];
  time: Scalars['String'];
};

export type PageViewsSummaryObject = {
  __typename?: 'PageViewsSummaryObject';
  data?: Maybe<Array<PageViewsSummaryItemObject>>;
};

export type PageViewsSummaryResponseObject = {
  __typename?: 'PageViewsSummaryResponseObject';
  error?: Maybe<PageViewsSummaryResponseObjectErrorObject>;
  object?: Maybe<PageViewsSummaryObject>;
};

export type PageViewsSummaryResponseObjectErrorObject = {
  __typename?: 'PageViewsSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentIntentError {
  CardDeclined = 'CardDeclined',
  FormNotFound = 'FormNotFound',
  InsufficientCapabilitiesForTransfer = 'InsufficientCapabilitiesForTransfer',
  InsufficientFunds = 'InsufficientFunds',
  OwnerNotFound = 'OwnerNotFound',
  UnknownError = 'UnknownError'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type PaymentIntentResponseObject = {
  __typename?: 'PaymentIntentResponseObject';
  error?: Maybe<PaymentIntentResponseObjectErrorObject>;
  object?: Maybe<PaymentIntentObject>;
};

export type PaymentIntentResponseObjectErrorObject = {
  __typename?: 'PaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentMethod {
  Ach = 'Ach',
  ApplePayOrGooglePay = 'ApplePayOrGooglePay',
  Card = 'Card',
  Cash = 'Cash',
  CashApp = 'CashApp',
  Cheque = 'Cheque',
  Free = 'Free',
  InKind = 'InKind',
  Manual = 'Manual',
  Other = 'Other',
  Pad = 'Pad',
  Stocks = 'Stocks',
  TapToPay = 'TapToPay',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export enum PaymentStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum PayoutInterval {
  Daily = 'Daily',
  Manual = 'Manual',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PayoutObject = {
  __typename?: 'PayoutObject';
  amount: Scalars['Float'];
  arrivalDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  externalAccount: ExternalAccountObject;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
};

export type PayoutResponseObject = {
  __typename?: 'PayoutResponseObject';
  error?: Maybe<PayoutResponseObjectErrorObject>;
  object?: Maybe<PayoutObject>;
};

export type PayoutResponseObjectErrorObject = {
  __typename?: 'PayoutResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PayoutsInfiniteListResponseObjectErrorObject = {
  __typename?: 'PayoutsInfiniteListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PostSubmitCommandObject = {
  __typename?: 'PostSubmitCommandObject';
  createFundraisingHandler?: Maybe<CreatedCampaignsObject>;
};

export type PostSubmitCommandObject7tKq4ltS = {
  __typename?: 'PostSubmitCommandObject7tKQ4ltS';
  error?: Maybe<PostSubmitCommandObject7tKq4ltSErrorObject>;
  object?: Maybe<PostSubmitCommandObject>;
};

export type PostSubmitCommandObject7tKq4ltSErrorObject = {
  __typename?: 'PostSubmitCommandObject7tKQ4ltSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PreviewReceiptParams = {
  amount?: InputMaybe<Scalars['Float']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  formType: FormType;
  locale?: InputMaybe<Scalars['String']>;
  rateName?: InputMaybe<Scalars['String']>;
  ticketingName?: InputMaybe<Scalars['String']>;
};

export type PreviewTicketParams = {
  address?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  endUtc?: InputMaybe<Scalars['Date']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  formTitle?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  rateTitle?: InputMaybe<Scalars['String']>;
  startUtc?: InputMaybe<Scalars['Date']>;
  ticketingColor?: InputMaybe<Scalars['String']>;
};

export type ProcessBalanceTransferInput = {
  offsetDay: Scalars['Float'];
};

export type ProductBidObject = {
  __typename?: 'ProductBidObject';
  amount: Scalars['Int'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  rate?: Maybe<RateObject>;
  rateId: Scalars['String'];
  status: ProductBidStatus;
};

export type ProductBidObjectOhqcP1Ku = {
  __typename?: 'ProductBidObjectOhqcP1KU';
  error?: Maybe<ProductBidObjectOhqcP1KuErrorObject>;
  items?: Maybe<Array<ProductBidObject>>;
};

export type ProductBidObjectOhqcP1KuErrorObject = {
  __typename?: 'ProductBidObjectOhqcP1KUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductBidStatus {
  Lost = 'Lost',
  Submitted = 'Submitted',
  Won = 'Won'
}

export type ProductDonationObject = {
  __typename?: 'ProductDonationObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  id: Scalars['String'];
  inHonourEmailAddress?: Maybe<Scalars['String']>;
  inHonourEmailBody?: Maybe<Scalars['String']>;
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  isRecurrent: Scalars['Boolean'];
  organizationId: Scalars['String'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: Maybe<Scalars['String']>;
};

export enum ProductDonationRecurrenceInterval {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly'
}

export type ProductDonationResponseObjectErrorObject = {
  __typename?: 'ProductDonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketCountsObject = {
  __typename?: 'ProductTicketCountsObject';
  id: Scalars['String'];
  productTicketCheckedIn: Scalars['Int'];
  productTicketTotal: Scalars['Int'];
};

export type ProductTicketCountsObjectyg4gVKxK = {
  __typename?: 'ProductTicketCountsObjectyg4gVKxK';
  error?: Maybe<ProductTicketCountsObjectyg4gVKxKErrorObject>;
  object?: Maybe<ProductTicketCountsObject>;
};

export type ProductTicketCountsObjectyg4gVKxKErrorObject = {
  __typename?: 'ProductTicketCountsObjectyg4gVKxKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketListResponseObject = {
  __typename?: 'ProductTicketListResponseObject';
  error?: Maybe<ProductTicketListResponseObjectErrorObject>;
  items?: Maybe<Array<ProductTicketObject>>;
};

export type ProductTicketListResponseObjectErrorObject = {
  __typename?: 'ProductTicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketObject = {
  __typename?: 'ProductTicketObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal?: Maybe<Scalars['Boolean']>;
  command?: Maybe<CommandObject>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  expirationDate?: Maybe<Scalars['Date']>;
  groupTicketId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  status: ProductTicketStatus;
  ticketingIndex?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type ProductTicketObjectctrzUrJu = {
  __typename?: 'ProductTicketObjectctrzURJu';
  error?: Maybe<ProductTicketObjectctrzUrJuErrorObject>;
  object?: Maybe<ProductTicketObject>;
};

export type ProductTicketObjectctrzUrJuErrorObject = {
  __typename?: 'ProductTicketObjectctrzURJuErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketResponseObjectErrorObject = {
  __typename?: 'ProductTicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductTicketStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CheckedIn = 'CheckedIn',
  Pending = 'Pending'
}

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject';
  cancelledCount: Scalars['String'];
  checkedInCount: Scalars['String'];
  filteredRowsEmails: Array<Scalars['String']>;
  notCheckedInCount: Scalars['String'];
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject7Bswk4Bn = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject7BSWK4BN';
  error?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject7Bswk4BnErrorObject>;
  object?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject>;
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject7Bswk4BnErrorObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject7BSWK4BNErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkIfResetPasswordIsValid: BooleanJSiEiegg;
  defaultQuery: Scalars['Boolean'];
  doesOrganizationHaveProductBids: BooleanazSplgkC;
  donationForm: DonationFormObjectlgFmnhUk;
  donationFormWithRestrictedAccess: DonationFormObject8Fn8NTjX;
  fetchAndSetSkipAIChat: BooleanWuhFugIm;
  generateCheckinManagerUrl: String0XOhBWpe;
  getActiveTicketingOccurrenceIds: StringM13Ycn3A;
  getAggregateProductTicketStatusCountPerTicketingPerOccurrence: ProductTicketStatusCountPerTicketingPerOccurrenceObject7Bswk4Bn;
  getAggregateRateFieldsByCommand: AggregateRateFieldsObjectbxMhWVvt;
  getApplicationInitialStatus: ApplicationInitialStatusResponseObject;
  getBalanceTransactionDetails: GetBalanceTransactionDetailsObject6xKp8Ub7;
  getCampaignFormsCount: Numberee2YrrX3;
  getCampaignParticipantContacts: ContactObjectuHqWw7Gg;
  getCampaignSubFormsCount: NumberResponseObject;
  getCampaignWithVolume?: Maybe<DonationFormResponseObject>;
  getCardVolume: CardVolumeResponseObject;
  getCommandAnswers: AnswerObjectFpVnvWiL;
  getContactCountByTag: NumberResponseObject;
  getContactDetails: ContactObjectNd92o8Re;
  getContactKeyInfo: ContactKeyInfoObjectkruN5p8q;
  getContactsFormRecipientIds: ContactObjectqHaGly5h;
  getCurrentUser?: Maybe<GetCurrentUserObject>;
  getCurrentlyActiveOrganizations: CurrentlyActiveOrganizationResponseListObject;
  getDiscount: DiscountResponseObject;
  getDonationFormForManualDonation: DonationFormObjectie9XmMvw;
  getDonationFormQuestions: QuestionListResponseObject;
  getDonationFormWithFundraiserAccess: DonationFormObject8lTdnXcM;
  getDonationFormsFromCampaign: GetDonationFormsFromCampaignObjectcAsEzHte;
  getDonationFormsList: DonationFormObjectqFshbS6E;
  getDonorVolume: DonorVolumeListResponseObject;
  getEmailDelivery: EmailDeliveryObjectSjSwafja;
  getEmailTemplates: EmailTemplateResponseListObject;
  getEmailsCountByStatus: EmailsCountByStatusTableListResponseObject;
  getEphemeralKey: GetEphemeralKeyObject4ItMbmwp;
  getFirstTransactionCreatedAtUtc: GetFirstTransactionCreatedAtUtcResponseObject8cMVuHrl;
  getFormVolume: NumberAgSakcpm;
  getFrontendCurrentUser: GetCurrentUserFrontendObjectrLvvjQ6I;
  getFundraisingOrganization: OrganizationResponseObject;
  getHomeGraphData: HomeGraphResponseListObject;
  getImportStatus: ImportUsersGoogleSheetObjectjBIuSbrW;
  getIsActivatedFeatureToggleBadTipperDisclaimer: BooleanxPqLJrSf;
  getIsNewMobileFormCreationFlow: Boolean683gzNyv;
  getIssuingCard: GetIssuingCardObjectLyp84duj;
  getIssuingRewardTransactions: GetIssuingRewardTransactionsObjectge8eqngD;
  getMarkerHoverDetails: MarkerHoverDetailsObject;
  getMarkerPositions: ClusterResponseObject;
  getMyReferralOrganizations: MyReferralOrganizationObjectglavFoyq;
  getMyReferrerInformation: MyReferrerOrganizationResponseObject;
  getNextReferralTransferAmount: NumberResponseObject;
  getNotes: NoteResponseListObject;
  getOrganizationAdmin: UserObjectO51TcPwx;
  getOrganizationEngagementFlags: OrganizationEngagementFlagsObjectgOImoy0l;
  getOrganizationForms: FrontendFormObjectResponseListObject;
  getOrganizationHasAnyTag: NumberResponseObject;
  getOrganizationListByInput: OrganizationAndOwnerObjectTd09oH9F;
  getOrganizationMembers: UserListResponseObject;
  getOrganizationOwner: UserResponseObject;
  getOrganizationSummary: GetOrganizationSummaryResponseObject;
  getOrganizationTags: TagListResponseObject;
  getPageViewsSummary: PageViewsSummaryResponseObject;
  getPayout: PayoutResponseObject;
  getProductTicketCountsForCheckin: ProductTicketCountsObjectyg4gVKxK;
  getProductTicketsCount: NumberResponseObjects85inbsb;
  getReferredOrganization: OrganizationResponseObject;
  getScheduledPayout: GetScheduledPayoutObjectcCnZzTpv;
  getSentryIssues: SentryIssueListResponseObject;
  getStripeAccountBalance: AccountBalancesObjectS543ofVy;
  getStripeAccountLink: String3JwcDeyy;
  getStripeCustomAccount: StripeAccountResponseObject;
  getTags: TagListResponseObject;
  getTagsByContactIds: ContactIdWithTagsObject1XuJIvFe;
  getTeamMembers: DonationFormObject080i07U8;
  getTeamWithVolume: DonationFormObjecthUik34Xh;
  getTeamsFromCampaignId: Array<DonationFormObject>;
  getTicketing: TicketingObjectprxXcBdt;
  getTicketingCategory: TicketingCategoryObjectB6cQm4Ji;
  getTicketingForManualOrder: TicketingObjectT415dTpl;
  getTicketingInfoForCheckin: TicketingObjectbeXZcVWe;
  getTicketingList: TicketingObjectBgtEqi9n;
  getTicketingReminderData: TicketingFieldObjectvyE2rGp4;
  getTipAndFeeAndRefundTipVolumeFromTransaction: TipNetVolumeResponseObject;
  getTipSuggestions: Array<TipSuggestionObject>;
  getTransaction: TransactionResponseObject;
  getTransactionCountOverTime: TransactionsOverTimeListResponseObject;
  getTransactionWithCommand: TransactionResponseObject;
  getTransactionsForIssuingBalanceTransfer: GetTransactionsForIssuingBalanceTransferObject8G7Mad3f;
  getUnsubscribePreferences: UnsubscribePreferencesObjectnsP1Ht6R;
  getVolumeAtDate: NumberResponseObject;
  getWebAnalytics: WebAnalyticsResponseObject;
  getWebhooksCountOverTime: WebhooksOverTimeListResponseObject;
  hasAnOccurrenceBefore: BooleanN9WqejMe;
  hasAnyReceipt: Booleanw0ChTv1J;
  hasContacts: BooleanResponseObject;
  hasEmailDelivery: HasEmailDeliveryObjectWWkFOy8H;
  isAnExistingUser: BooleanIdxljhBi;
  listCampaignFormTitles: FormTitleListResponse;
  listCommandsFromByOccurrenceId: CommandObjectyOLci0Fr;
  listDashboardCommandBidRates: BidRateObjectcds6iqEp;
  listDashboardCommandForms: DashboardCommandFormListResponse;
  listDashboardCommandRates: DashboardCommandRatesListResponseObject;
  listDashboardContactTransactionsCount: DashboardCountObjectZahfrMFl;
  listDashboardTransactionsRates: TransactionRatesWithQuantityObjectte4g21cz;
  listDonorDashboardCommandForms: DashboardCommandFormListResponse;
  listDonorDashboardTransactionsCount: IntResponseObject;
  listExpiringTransactionTickets: ProductTicketListResponseObject;
  listFormsForManualCommand: FrontendFormObject9rrYt8X0;
  listOrganizationFormTitles: FormTitleListResponse;
  listQuestionsWithChoices: QuestionListResponseObject;
  listTransactionBids: ProductBidObjectOhqcP1Ku;
  listTransactionTickets: ListTransactionTicketsResponse;
  listTransactionsWithFullyCanceledTickets: StringListResponseObject;
  organizationFormsAsMultiselectValues: MultiselectValueObjectQQmQdcEq;
  organizationOccurrencesAsMultiselectValues: MultiselectOccurrenceValueObjectSoVvz9E7;
  organizationTagsAsMultiselectValues: MultiselectValueResponseListObject;
  retrieveCommand: CommandObjecth3sc04vI;
  retrieveCommandAnswers: AnswerResponseListObject;
  retrieveContact: ContactResponseObject;
  retrieveDashboardTransaction: DashboardTransactionObjectK0Jz2ahO;
  retrieveDonorCommand: CommandObjectMzAzltV2;
  retrieveDonorDashboardSubscription: CommandSubscriptionResponseObject;
  retrieveDonorDashboardTransaction: DashboardTransactionObjectQfg5Kqrm;
  retrieveDonorReceipt: ReceiptResponseObject;
  retrieveForm: FrontendFormObjectResponseObject;
  retrieveOrganization: OrganizationPublicResponseObject;
  retrieveProductTicketAnswers: AnswerObjectJl6nJPts;
  retrieveProductTicketAnswersForCheckin: AnswerObjectlTl3h849;
  retrieveReceipt: ReceiptResponseObject;
  retrieveTransactionReceipts: ReceiptObjectCvvTl2rx;
  searchNonprofit: NewNonprofitObject9NbwQpHk;
  searchUnsplashImages: SearchUnsplashImagesResponseObject;
  shouldDisplayPostOccurrenceThankYouEmail: BooleanITefAan0;
  ticketingRatesAsMultiselectValues: MultiselectValueObjectuwbVckTm;
};


export type QueryCheckIfResetPasswordIsValidArgs = {
  checkIfResetPasswordIsValidInput: CheckIfResetPasswordIsValidInput;
};


export type QueryDonationFormArgs = {
  donationFormIdOrUrlPath: Scalars['String'];
};


export type QueryDonationFormWithRestrictedAccessArgs = {
  donationFormIdInput: UuidInput;
};


export type QueryGenerateCheckinManagerUrlArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetActiveTicketingOccurrenceIdsArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateProductTicketStatusCountPerTicketingPerOccurrenceArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateRateFieldsByCommandArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetBalanceTransactionDetailsArgs = {
  balanceTransactionId: Scalars['String'];
  type: BalanceTransactionType;
};


export type QueryGetCampaignFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignParticipantContactsArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignSubFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignWithVolumeArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCardVolumeArgs = {
  parameters?: InputMaybe<HomeMetricsInput>;
};


export type QueryGetCommandAnswersArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetContactCountByTagArgs = {
  tagId: Scalars['String'];
};


export type QueryGetContactDetailsArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactKeyInfoArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactsFormRecipientIdsArgs = {
  recipientIds: Array<Scalars['String']>;
};


export type QueryGetCurrentlyActiveOrganizationsArgs = {
  input?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetDonationFormForManualDonationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormQuestionsArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormWithFundraiserAccessArgs = {
  donationFormIdInput: UuidInput;
  token: Scalars['String'];
};


export type QueryGetDonationFormsFromCampaignArgs = {
  campaignId: Scalars['String'];
  filters: DonationFormCampaignFilterInput;
};


export type QueryGetDonationFormsListArgs = {
  withArchived: Scalars['Boolean'];
};


export type QueryGetEmailDeliveryArgs = {
  emailDeliveryIdInput: UuidInput;
};


export type QueryGetEmailsCountByStatusArgs = {
  emailDeliveryId: Scalars['String'];
};


export type QueryGetEphemeralKeyArgs = {
  input: GetEphemeralKeyInput;
};


export type QueryGetFormVolumeArgs = {
  formInput: FormInput;
};


export type QueryGetFundraisingOrganizationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetHomeGraphDataArgs = {
  parameters: HomeGraphInput;
};


export type QueryGetImportStatusArgs = {
  importId: Scalars['String'];
};


export type QueryGetMarkerHoverDetailsArgs = {
  getMarkerHoverDetailsInput: GetMarkerHoverDetailsInput;
};


export type QueryGetMarkerPositionsArgs = {
  getMarkerInput: GetMarkersInput;
};


export type QueryGetNotesArgs = {
  userId: Scalars['String'];
};


export type QueryGetOrganizationAdminArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationFormsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationListByInputArgs = {
  searchOragnizationInput: Scalars['String'];
};


export type QueryGetOrganizationOwnerArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationSummaryArgs = {
  locale: Locales;
  organizationId: Scalars['String'];
};


export type QueryGetPageViewsSummaryArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetPayoutArgs = {
  id: Scalars['String'];
};


export type QueryGetProductTicketCountsForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetProductTicketsCountArgs = {
  commandId: Scalars['String'];
};


export type QueryGetReferredOrganizationArgs = {
  params: GetReferredOrganizationInput;
};


export type QueryGetSentryIssuesArgs = {
  slot: SentrySlot;
};


export type QueryGetTagsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTagsByContactIdsArgs = {
  contactIds: Array<Scalars['String']>;
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamWithVolumeArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsFromCampaignIdArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingCategoryArgs = {
  params: UuidInput;
};


export type QueryGetTicketingForManualOrderArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingInfoForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetTicketingReminderDataArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTipSuggestionsArgs = {
  getTipSuggestionsInput: GetTipSuggestionsInput;
};


export type QueryGetTransactionArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetTransactionWithCommandArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionsForIssuingBalanceTransferArgs = {
  input: ProcessBalanceTransferInput;
};


export type QueryGetVolumeAtDateArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetWebAnalyticsArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetWebhooksCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryHasAnOccurrenceBeforeArgs = {
  days: Scalars['Int'];
  ticketingId: Scalars['String'];
};


export type QueryIsAnExistingUserArgs = {
  email: Scalars['String'];
};


export type QueryListCampaignFormTitlesArgs = {
  campaignId: Scalars['String'];
};


export type QueryListCommandsFromByOccurrenceIdArgs = {
  occurrenceId: Scalars['String'];
};


export type QueryListDashboardCommandBidRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListDashboardCommandRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardContactTransactionsCountArgs = {
  userId: Scalars['String'];
};


export type QueryListDashboardTransactionsRatesArgs = {
  locale: Locales;
  transactionIds: Array<Scalars['String']>;
};


export type QueryListDonorDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListExpiringTransactionTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryListQuestionsWithChoicesArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  questionIds: Array<Scalars['String']>;
};


export type QueryListTransactionBidsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionTicketsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionsWithFullyCanceledTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryOrganizationOccurrencesAsMultiselectValuesArgs = {
  formIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRetrieveCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveCommandAnswersArgs = {
  commandId: Scalars['String'];
};


export type QueryRetrieveContactArgs = {
  contactId: Scalars['String'];
};


export type QueryRetrieveDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveFormArgs = {
  formInput: FormInput;
};


export type QueryRetrieveOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveProductTicketAnswersArgs = {
  productTicketIds: Array<Scalars['String']>;
};


export type QueryRetrieveProductTicketAnswersForCheckinArgs = {
  productTicketIds: Array<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryRetrieveReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveTransactionReceiptsArgs = {
  transactionId: Scalars['String'];
};


export type QuerySearchNonprofitArgs = {
  filters: SearchNonprofitFilterInput;
  query: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  searchUnsplashImagesInput: SearchUnsplashImagesInput;
};


export type QueryShouldDisplayPostOccurrenceThankYouEmailArgs = {
  occurrenceIdInput: UuidInput;
};


export type QueryTicketingRatesAsMultiselectValuesArgs = {
  formId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  questionId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldObject = {
  __typename?: 'QuestionFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  donationFormId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  questionFields: Array<QuestionFieldInput>;
  ratesAssigned?: InputMaybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  ticketingId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionListResponseObject = {
  __typename?: 'QuestionListResponseObject';
  error?: Maybe<QuestionListResponseObjectErrorObject>;
  items?: Maybe<Array<QuestionObject>>;
};

export type QuestionListResponseObjectErrorObject = {
  __typename?: 'QuestionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type QuestionObject = {
  __typename?: 'QuestionObject';
  answers?: Maybe<Array<AnswerObject>>;
  choices: Array<ChoiceObject>;
  countAlreadyAnswered: Scalars['Float'];
  hasRate: Scalars['Boolean'];
  id: Scalars['String'];
  questionFields: Array<QuestionFieldObject>;
  rateQuestions?: Maybe<Array<RateQuestionObject>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  type: QuestionType;
};

export enum QuestionType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Email = 'Email',
  Multiple = 'Multiple',
  MultipleCheckboxes = 'MultipleCheckboxes',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Simple = 'Simple',
  Utm = 'Utm'
}

export type RateByOccurrenceObject = {
  __typename?: 'RateByOccurrenceObject';
  rateId: Scalars['String'];
  remainingTickets?: Maybe<Scalars['Float']>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
};

export type RateFieldObject = {
  __typename?: 'RateFieldObject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type RateFieldObjectWithCount = {
  __typename?: 'RateFieldObjectWithCount';
  attendeesByTicket?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  locale: Locales;
  rateFieldCount: Scalars['Float'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type RateListResponseObjectErrorObject = {
  __typename?: 'RateListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RateObject = {
  __typename?: 'RateObject';
  allowAutomaticRenewal?: Maybe<Scalars['Boolean']>;
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  attendeesByTicket?: Maybe<Scalars['Float']>;
  bidIncrement?: Maybe<Scalars['Float']>;
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isRateFull?: Maybe<Scalars['Boolean']>;
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: Maybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: Maybe<Scalars['Date']>;
  minimumToBuy?: Maybe<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  rateFields?: Maybe<Array<RateFieldObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  tickets?: Maybe<Array<TicketObject>>;
};

export type RateQuestionObject = {
  __typename?: 'RateQuestionObject';
  id: Scalars['String'];
  rateId: Scalars['String'];
};

export type RatesWithQuantityObject = {
  __typename?: 'RatesWithQuantityObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type ReceiptListResponseObjectErrorObject = {
  __typename?: 'ReceiptListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObject = {
  __typename?: 'ReceiptObject';
  address?: Maybe<Scalars['String']>;
  advantageAmount?: Maybe<Scalars['Float']>;
  advantageDescription?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  charityNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  issuingAtUtc: Scalars['Date'];
  lastName?: Maybe<Scalars['String']>;
  organizationAddress: Scalars['String'];
  organizationCity: Scalars['String'];
  organizationCountry: OrganizationCountry;
  organizationName: Scalars['String'];
  organizationPostalCode: Scalars['String'];
  organizationRegion: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  receiptNumber: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  status: ReceiptStatus;
  transactions?: Maybe<Array<TransactionObject>>;
  userId?: Maybe<Scalars['String']>;
};

export type ReceiptObjectCvvTl2rx = {
  __typename?: 'ReceiptObjectCvvTL2rx';
  error?: Maybe<ReceiptObjectCvvTl2rxErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectCvvTl2rxErrorObject = {
  __typename?: 'ReceiptObjectCvvTL2rxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObjectVoeDpBfe = {
  __typename?: 'ReceiptObjectVoeDpBFE';
  error?: Maybe<ReceiptObjectVoeDpBfeErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectVoeDpBfeErrorObject = {
  __typename?: 'ReceiptObjectVoeDpBFEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptResponseObject = {
  __typename?: 'ReceiptResponseObject';
  error?: Maybe<ReceiptResponseObjectErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptResponseObjectErrorObject = {
  __typename?: 'ReceiptResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ReceiptStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Pending = 'Pending'
}

export type ReceiptTableListResponseObjectErrorObject = {
  __typename?: 'ReceiptTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RecipientObject = {
  __typename?: 'RecipientObject';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
};

export type RedirectToUrlObject = {
  __typename?: 'RedirectToUrlObject';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ReferralQuestionChoice {
  AsDonor = 'AsDonor',
  Capterra = 'Capterra',
  Nbctc = 'Nbctc',
  OrganicSearch = 'OrganicSearch',
  Other = 'Other',
  ReferralFromDonor = 'ReferralFromDonor',
  ReferralFromOrganization = 'ReferralFromOrganization',
  SocialNetwork = 'SocialNetwork'
}

export type RefundObject = {
  __typename?: 'RefundObject';
  id: Scalars['String'];
};

export type RefundResponseObject = {
  __typename?: 'RefundResponseObject';
  error?: Maybe<RefundResponseObjectErrorObject>;
  object?: Maybe<RefundObject>;
};

export type RefundResponseObjectErrorObject = {
  __typename?: 'RefundResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};

export enum ResetPasswordLinkError {
  EmailDoesNotExist = 'EmailDoesNotExist'
}

export type ResetPasswordLinkObject = {
  __typename?: 'ResetPasswordLinkObject';
  user?: Maybe<UserObject>;
};

export type ResetPasswordLinkResponseObject = {
  __typename?: 'ResetPasswordLinkResponseObject';
  error?: Maybe<ResetPasswordLinkResponseObjectErrorObject>;
  object?: Maybe<ResetPasswordLinkObject>;
};

export type ResetPasswordLinkResponseObjectErrorObject = {
  __typename?: 'ResetPasswordLinkResponseObjectErrorObject';
  code?: Maybe<ResetPasswordLinkError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RetroActiveReferralInput = {
  orgReferredEmail: Scalars['String'];
  orgReferringEmail: Scalars['String'];
};

export type ScheduledPayoutObject = {
  __typename?: 'ScheduledPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type SearchNonprofitFilterInput = {
  country?: InputMaybe<OrganizationCountry>;
};

export type SearchUnsplashImagesInput = {
  orderBy: UnsplashOrderBy;
  orientation?: InputMaybe<UnsplashOrientation>;
  page: Scalars['Float'];
  query?: InputMaybe<Scalars['String']>;
};

export type SearchUnsplashImagesObject = {
  __typename?: 'SearchUnsplashImagesObject';
  nextPage: Scalars['Float'];
  results: Array<UnsplashImageObject>;
  total: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type SearchUnsplashImagesResponseObject = {
  __typename?: 'SearchUnsplashImagesResponseObject';
  error?: Maybe<SearchUnsplashImagesResponseObjectErrorObject>;
  object?: Maybe<SearchUnsplashImagesObject>;
};

export type SearchUnsplashImagesResponseObjectErrorObject = {
  __typename?: 'SearchUnsplashImagesResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SendEmailDeliveryError {
  DesignConfigMissing = 'DesignConfigMissing',
  EmailDeliveryNotFound = 'EmailDeliveryNotFound',
  FailedToExportUnlayerHtml = 'FailedToExportUnlayerHtml',
  FailedToGetOwnerEmail = 'FailedToGetOwnerEmail',
  FailedToGetRecipients = 'FailedToGetRecipients',
  FailedToGetReminderRecipients = 'FailedToGetReminderRecipients',
  FailedToGetSmartInviteRecipients = 'FailedToGetSmartInviteRecipients',
  FormNotFound = 'FormNotFound',
  LimitExceeded = 'LimitExceeded',
  ManyRecipientsInPreview = 'ManyRecipientsInPreview',
  SenderNoEmail = 'SenderNoEmail',
  SendingFailed = 'SendingFailed',
  StripeAccountNotActive = 'StripeAccountNotActive',
  UnexpectedError = 'UnexpectedError'
}

export type SendEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'SendEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<SendEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendMergeUsersRequestResponseObject = {
  __typename?: 'SendMergeUsersRequestResponseObject';
  error?: Maybe<SendMergeUsersRequestResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type SendMergeUsersRequestResponseObjectErrorObject = {
  __typename?: 'SendMergeUsersRequestResponseObjectErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendResetPasswordRequestInput = {
  email: Scalars['String'];
  locale: Locales;
};

export type SentryIssueListResponseObject = {
  __typename?: 'SentryIssueListResponseObject';
  error?: Maybe<SentryIssueListResponseObjectErrorObject>;
  items?: Maybe<Array<SentryIssueObject>>;
};

export type SentryIssueListResponseObjectErrorObject = {
  __typename?: 'SentryIssueListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SentryIssueObject = {
  __typename?: 'SentryIssueObject';
  count: Scalars['Float'];
  culprit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  value?: Maybe<Scalars['String']>;
};

export type SentryIssueResponseObjectErrorObject = {
  __typename?: 'SentryIssueResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SentrySlot {
  OneHour = 'OneHour',
  SevenDays = 'SevenDays',
  SeventyTwoHours = 'SeventyTwoHours',
  TwentyFourHours = 'TwentyFourHours'
}

export type SessionObject = {
  __typename?: 'SessionObject';
  amount?: Maybe<Scalars['Float']>;
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
};

export type SessionResponseObject = {
  __typename?: 'SessionResponseObject';
  error?: Maybe<SessionResponseObjectErrorObject>;
  object?: Maybe<SessionObject>;
};

export type SessionResponseObjectErrorObject = {
  __typename?: 'SessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SignInAsAdministratorInput = {
  email: Scalars['String'];
  emailTarget: Scalars['String'];
  password: Scalars['String'];
};

export type SignInFrontendInput = {
  email?: InputMaybe<Scalars['String']>;
  googleToken?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  smsCode?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
  telemetryId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};

export type SignInInput = {
  email: Scalars['String'];
  encryptPassword?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  charityNumber?: InputMaybe<Scalars['String']>;
  country: OrganizationCountry;
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleToken?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isNonprofitSearchSuccessful?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Locales;
  npoStarterDealId?: InputMaybe<Scalars['String']>;
  organizationName: Scalars['String'];
  password: Scalars['String'];
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  referredByOrganizationId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ReferralQuestionChoice>;
  ssoStytchData?: InputMaybe<SsoStytchData>;
  type?: InputMaybe<OrganizationType>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SsoStytchData = {
  provider: Scalars['String'];
  stytchOrganizationId: Scalars['String'];
  stytchSessionJwt: Scalars['String'];
  stytchUserId: Scalars['String'];
};

export type String0XOhBWpe = {
  __typename?: 'String0XOhBWpe';
  error?: Maybe<String0XOhBWpeErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String0XOhBWpeErrorObject = {
  __typename?: 'String0XOhBWpeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type String3JwcDeyy = {
  __typename?: 'String3JwcDeyy';
  error?: Maybe<String3JwcDeyyErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String3JwcDeyyErrorObject = {
  __typename?: 'String3JwcDeyyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type String51Mbgxim = {
  __typename?: 'String51MBGXIM';
  error?: Maybe<String51MbgximErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String51MbgximErrorObject = {
  __typename?: 'String51MBGXIMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringLEwOod15 = {
  __typename?: 'StringLEwOod15';
  error?: Maybe<StringLEwOod15ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringLEwOod15ErrorObject = {
  __typename?: 'StringLEwOod15ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringListResponseObject = {
  __typename?: 'StringListResponseObject';
  error?: Maybe<StringListResponseObjectErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringListResponseObjectErrorObject = {
  __typename?: 'StringListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringM13Ycn3A = {
  __typename?: 'StringM13Ycn3A';
  error?: Maybe<StringM13Ycn3AErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringM13Ycn3AErrorObject = {
  __typename?: 'StringM13Ycn3AErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringResponseObject = {
  __typename?: 'StringResponseObject';
  error?: Maybe<StringResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringResponseObjectErrorObject = {
  __typename?: 'StringResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringShzGjcKz = {
  __typename?: 'StringShzGJCKz';
  error?: Maybe<StringShzGjcKzErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringShzGjcKzErrorObject = {
  __typename?: 'StringShzGJCKzErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringW81iFstI = {
  __typename?: 'StringW81iFstI';
  error?: Maybe<StringW81iFstIErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringW81iFstIErrorObject = {
  __typename?: 'StringW81iFstIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Stringixfcs8E6 = {
  __typename?: 'Stringixfcs8E6';
  error?: Maybe<Stringixfcs8E6ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type Stringixfcs8E6ErrorObject = {
  __typename?: 'Stringixfcs8E6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringmlsZbkcs = {
  __typename?: 'StringmlsZBKCS';
  error?: Maybe<StringmlsZbkcsErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringmlsZbkcsErrorObject = {
  __typename?: 'StringmlsZBKCSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeAccountObject = {
  __typename?: 'StripeAccountObject';
  capabilities: StripeCapabilities;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccountObject>;
  future_requirements?: Maybe<StripeRequirements>;
  hasAtLeastOnePayout?: Maybe<Scalars['Boolean']>;
  hasOnlyOnePayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isBankConnected: Scalars['Boolean'];
  isRejectedByStripe?: Maybe<Scalars['Boolean']>;
  isRejectedByZeffy?: Maybe<Scalars['Boolean']>;
  lastPayout?: Maybe<PayoutObject>;
  payoutInterval: PayoutInterval;
  requirements?: Maybe<StripeRequirements>;
  type?: Maybe<Scalars['String']>;
};

export type StripeAccountResponseObject = {
  __typename?: 'StripeAccountResponseObject';
  error?: Maybe<StripeAccountResponseObjectErrorObject>;
  object?: Maybe<StripeAccountObject>;
};

export type StripeAccountResponseObjectErrorObject = {
  __typename?: 'StripeAccountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_issuing?: Maybe<StripeCapabilityStatus>;
  transfers?: Maybe<StripeCapabilityStatus>;
  treasury?: Maybe<StripeCapabilityStatus>;
};

export enum StripeCapabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type StripeCustomerObject = {
  __typename?: 'StripeCustomerObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  userId: Scalars['String'];
};

export enum StripePayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  inTransit = 'inTransit',
  paid = 'paid',
  pending = 'pending'
}

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  current_deadline?: Maybe<Scalars['Float']>;
  currently_due?: Maybe<Array<Scalars['String']>>;
  pending_verification?: Maybe<Array<Scalars['String']>>;
};

export enum SubscriptionRecurrenceInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SubscriptionStatus {
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export type SwitchOrgAsAdministratorInput = {
  emailTarget: Scalars['String'];
};

export type TagListResponseObject = {
  __typename?: 'TagListResponseObject';
  error?: Maybe<TagListResponseObjectErrorObject>;
  items?: Maybe<Array<TagObject>>;
};

export type TagListResponseObjectErrorObject = {
  __typename?: 'TagListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type TicketListResponseObjectErrorObject = {
  __typename?: 'TicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketObject = {
  __typename?: 'TicketObject';
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  order: OrderObject;
  orderId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  ticketingIndex?: Maybe<Scalars['Float']>;
};

export type TicketResponseObjectErrorObject = {
  __typename?: 'TicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingCategoryObject = {
  __typename?: 'TicketingCategoryObject';
  formCategory: TicketingFormCategory;
  id: Scalars['String'];
};

export type TicketingCategoryObjectB6cQm4Ji = {
  __typename?: 'TicketingCategoryObjectB6cQM4JI';
  error?: Maybe<TicketingCategoryObjectB6cQm4JiErrorObject>;
  object?: Maybe<TicketingCategoryObject>;
};

export type TicketingCategoryObjectB6cQm4JiErrorObject = {
  __typename?: 'TicketingCategoryObjectB6cQM4JIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingDetailsRate = {
  __typename?: 'TicketingDetailsRate';
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  fields: Array<RateFieldObject>;
  id: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
};

export type TicketingDonationFormObject = {
  __typename?: 'TicketingDonationFormObject';
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  registrationIncreaseThermometer: Scalars['Boolean'];
};

export type TicketingFeatureFlagObject = {
  __typename?: 'TicketingFeatureFlagObject';
  isPaymentByPadAllowed: Scalars['Boolean'];
};

export type TicketingFieldObject = {
  __typename?: 'TicketingFieldObject';
  bidLoserEmailContent?: Maybe<Scalars['String']>;
  bidLoserEmailObject?: Maybe<Scalars['String']>;
  chequeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: Maybe<Scalars['String']>;
  postEventBody?: Maybe<Scalars['String']>;
  postEventSubject?: Maybe<Scalars['String']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  reminderBody?: Maybe<Scalars['String']>;
  reminderSubject?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TicketingFieldObjectvyE2rGp4 = {
  __typename?: 'TicketingFieldObjectvyE2rGP4';
  error?: Maybe<TicketingFieldObjectvyE2rGp4ErrorObject>;
  object?: Maybe<TicketingFieldObject>;
};

export type TicketingFieldObjectvyE2rGp4ErrorObject = {
  __typename?: 'TicketingFieldObjectvyE2rGP4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingFieldResponseObjectErrorObject = {
  __typename?: 'TicketingFieldResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum TicketingFormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  Shop = 'Shop'
}

export type TicketingListResponseObjectErrorObject = {
  __typename?: 'TicketingListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject = {
  __typename?: 'TicketingObject';
  address?: Maybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerPdfUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  color: Scalars['String'];
  coordinates: CoordinatesObject;
  discounts?: Maybe<Array<DiscountObject>>;
  displayAddressQuestion: Scalars['Boolean'];
  donationForm?: Maybe<TicketingDonationFormObject>;
  eventInvitationEmailDeliveryId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  eventTimezone?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateExtraDonationReceipt: Scalars['Boolean'];
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isTicketingFull: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  moneyGathered?: Maybe<Scalars['Float']>;
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  occurrences?: Maybe<Array<TicketingOccurrenceObject>>;
  occurrencesWithRates?: Maybe<Array<OccurrenceWithRatesObject>>;
  orders?: Maybe<Array<OrderObject>>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  postEventEmailDeliveryId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  postEventSendDateOffset?: Maybe<Scalars['Float']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionObject>>;
  rates?: Maybe<Array<RateObject>>;
  recurrences?: Maybe<Array<TicketingRecurrenceObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  reminderSendDateOffset?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  soldTicketsCount: Scalars['Float'];
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  thermometerTarget?: Maybe<Scalars['Float']>;
  ticketingFeatureFlagTreatment?: Maybe<TicketingFeatureFlagObject>;
  ticketingFields: Array<TicketingFieldObject>;
  withoutMap: Scalars['Boolean'];
};

export type TicketingObject3U5jLv13 = {
  __typename?: 'TicketingObject3U5jLv13';
  error?: Maybe<TicketingObject3U5jLv13ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObject3U5jLv13ErrorObject = {
  __typename?: 'TicketingObject3U5jLv13ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectBgtEqi9n = {
  __typename?: 'TicketingObjectBgtEQI9n';
  error?: Maybe<TicketingObjectBgtEqi9nErrorObject>;
  items?: Maybe<Array<TicketingObject>>;
};

export type TicketingObjectBgtEqi9nErrorObject = {
  __typename?: 'TicketingObjectBgtEQI9nErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectEyBqyLcD = {
  __typename?: 'TicketingObjectEyBqyLcD';
  error?: Maybe<TicketingObjectEyBqyLcDErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectEyBqyLcDErrorObject = {
  __typename?: 'TicketingObjectEyBqyLcDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectFi86yTxf = {
  __typename?: 'TicketingObjectFi86yTXF';
  error?: Maybe<TicketingObjectFi86yTxfErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectFi86yTxfErrorObject = {
  __typename?: 'TicketingObjectFi86yTXFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectJcp7Owcl = {
  __typename?: 'TicketingObjectJCP7Owcl';
  error?: Maybe<TicketingObjectJcp7OwclErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectJcp7OwclErrorObject = {
  __typename?: 'TicketingObjectJCP7OwclErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectQenK0Vcb = {
  __typename?: 'TicketingObjectQenK0VCB';
  error?: Maybe<TicketingObjectQenK0VcbErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectQenK0VcbErrorObject = {
  __typename?: 'TicketingObjectQenK0VCBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectT415dTpl = {
  __typename?: 'TicketingObjectT415dTpl';
  error?: Maybe<TicketingObjectT415dTplErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectT415dTplErrorObject = {
  __typename?: 'TicketingObjectT415dTplErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectTvYoVw0l = {
  __typename?: 'TicketingObjectTVYoVW0l';
  error?: Maybe<TicketingObjectTvYoVw0lErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectTvYoVw0lErrorObject = {
  __typename?: 'TicketingObjectTVYoVW0lErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectbeXZcVWe = {
  __typename?: 'TicketingObjectbeXZcVWe';
  error?: Maybe<TicketingObjectbeXZcVWeErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectbeXZcVWeErrorObject = {
  __typename?: 'TicketingObjectbeXZcVWeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectdjo3Gl0m = {
  __typename?: 'TicketingObjectdjo3GL0m';
  error?: Maybe<TicketingObjectdjo3Gl0mErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectdjo3Gl0mErrorObject = {
  __typename?: 'TicketingObjectdjo3GL0mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectprxXcBdt = {
  __typename?: 'TicketingObjectprxXcBDT';
  error?: Maybe<TicketingObjectprxXcBdtErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectprxXcBdtErrorObject = {
  __typename?: 'TicketingObjectprxXcBDTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingOccurrenceObject = {
  __typename?: 'TicketingOccurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Boolean']>;
  endUtc: Scalars['Date'];
  eventReminderEmailDelivery?: Maybe<EmailDeliveryObject>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  ticketingId: Scalars['String'];
};

export type TicketingPaymentIntentInput = {
  amount: Scalars['Float'];
  commandId?: InputMaybe<Scalars['String']>;
  connectedAccountId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
  tip: Scalars['Float'];
  ttpoi?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingRecurrenceInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  repeatUntilDate?: InputMaybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  type: TicketingRecurrenceType;
};

export type TicketingRecurrenceObject = {
  __typename?: 'TicketingRecurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  repeatUntilDate?: Maybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  ticketingId?: Maybe<Scalars['String']>;
  type: TicketingRecurrenceType;
};

export enum TicketingRecurrenceType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  WeekDays = 'WeekDays',
  Weekly = 'Weekly'
}

export type TicketingResponseObjectErrorObject = {
  __typename?: 'TicketingResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingSummaryObject = {
  __typename?: 'TicketingSummaryObject';
  description: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
};

export enum TipModelExperience {
  Model1 = 'Model1',
  ModelA = 'ModelA',
  ModelB = 'ModelB',
  ModelC = 'ModelC',
  ModelD = 'ModelD',
  ModelE = 'ModelE'
}

export type TipNetVolumeObject = {
  __typename?: 'TipNetVolumeObject';
  volumeFee: Scalars['Float'];
  volumeTip: Scalars['Float'];
};

export type TipNetVolumeResponseObject = {
  __typename?: 'TipNetVolumeResponseObject';
  error?: Maybe<TipNetVolumeResponseObjectErrorObject>;
  object?: Maybe<TipNetVolumeObject>;
};

export type TipNetVolumeResponseObjectErrorObject = {
  __typename?: 'TipNetVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TipSuggestionObject = {
  __typename?: 'TipSuggestionObject';
  amount: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type TipSuggestionResponseListObjectErrorObject = {
  __typename?: 'TipSuggestionResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  stripePayoutId?: InputMaybe<Scalars['String']>;
};

export type TransactionObject = {
  __typename?: 'TransactionObject';
  command?: Maybe<CommandObject>;
  contact?: Maybe<ContactObject>;
  country: OrganizationCountry;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  donation?: Maybe<DonationObject>;
  donationId?: Maybe<Scalars['String']>;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning: Scalars['Boolean'];
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isImported: Scalars['Boolean'];
  isSample?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  nextPaymentAmount?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderObject>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  paymentMethod: PaymentMethod;
  receipt?: Maybe<ReceiptObject>;
  receiptId?: Maybe<Scalars['String']>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferGroup?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Float'];
};

export type TransactionObject4siFytBa = {
  __typename?: 'TransactionObject4siFytBa';
  error?: Maybe<TransactionObject4siFytBaErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObject4siFytBaErrorObject = {
  __typename?: 'TransactionObject4siFytBaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionObjectJrMafMa9 = {
  __typename?: 'TransactionObjectJRMafMa9';
  error?: Maybe<TransactionObjectJrMafMa9ErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjectJrMafMa9ErrorObject = {
  __typename?: 'TransactionObjectJRMafMa9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionRatesWithQuantityObject = {
  __typename?: 'TransactionRatesWithQuantityObject';
  id: Scalars['String'];
  rates?: Maybe<Array<RatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectte4g21cz = {
  __typename?: 'TransactionRatesWithQuantityObjectte4g21cz';
  error?: Maybe<TransactionRatesWithQuantityObjectte4g21czErrorObject>;
  items?: Maybe<Array<TransactionRatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectte4g21czErrorObject = {
  __typename?: 'TransactionRatesWithQuantityObjectte4g21czErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionResponseObject = {
  __typename?: 'TransactionResponseObject';
  error?: Maybe<TransactionResponseObjectErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionResponseObjectErrorObject = {
  __typename?: 'TransactionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeListResponseObject = {
  __typename?: 'TransactionsOverTimeListResponseObject';
  error?: Maybe<TransactionsOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<TransactionsOverTimeObject>>;
};

export type TransactionsOverTimeListResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeObject = {
  __typename?: 'TransactionsOverTimeObject';
  created: Scalars['Float'];
  failed: Scalars['Float'];
  processing: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type TransactionsOverTimeResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TranslationsDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  choiceFields?: InputMaybe<Array<ChoiceFieldInput>>;
  description: Scalars['String'];
  donationFormAmountFields: Array<EditDonationFormAmountTranslationInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  message?: InputMaybe<Scalars['String']>;
  questionsFields?: InputMaybe<Array<QuestionFieldInput>>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  transferDescription?: InputMaybe<Scalars['String']>;
};

export type UnsplashImageObject = {
  __typename?: 'UnsplashImageObject';
  alt_description?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['String'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
  width: Scalars['Float'];
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  download_location: Scalars['String'];
};

export enum UnsplashOrderBy {
  latest = 'latest',
  relevant = 'relevant'
}

export enum UnsplashOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  squarish = 'squarish'
}

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  raw: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnsubscribePreferenceInput = {
  unsubscribedFromEmailTypes?: InputMaybe<Array<EmailType>>;
};

export type UnsubscribePreferencesObject = {
  __typename?: 'UnsubscribePreferencesObject';
  id: Scalars['String'];
  unsubscribedFromEmailTypes: Array<EmailType>;
};

export type UnsubscribePreferencesObjectnsP1Ht6R = {
  __typename?: 'UnsubscribePreferencesObjectnsP1Ht6R';
  error?: Maybe<UnsubscribePreferencesObjectnsP1Ht6RErrorObject>;
  object?: Maybe<UnsubscribePreferencesObject>;
};

export type UnsubscribePreferencesObjectnsP1Ht6RErrorObject = {
  __typename?: 'UnsubscribePreferencesObjectnsP1Ht6RErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateAdministrativeInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankDescriptor?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  hideFromZeffyMap?: InputMaybe<Scalars['Boolean']>;
  isPoBoxAddress: Scalars['Boolean'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propublicaCCode?: InputMaybe<Scalars['Float']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  signatureUrl?: InputMaybe<Scalars['String']>;
  type: OrganizationType;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministrativeMoreInformationInput = {
  category: OrganizationCategory;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
};

export type UpdateDonationFormAmountInput = {
  amount: Scalars['Float'];
  donationFormFieldId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type UpdateOrganizationEngagementFlagsInput = {
  engagementAddContactTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactNotesTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactPaymentsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactTagsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactsBulkTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailRecipientsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailStatsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailTemplatesTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentDetailsTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentsExportTour?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateOrganizationHubspotPropertyInput = {
  property: Scalars['String'];
  value: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  billedAmount: Scalars['Int'];
  donationId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  transferAmount: Scalars['Int'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  error?: Maybe<UpdatePaymentIntentResponseErrorObject>;
  object?: Maybe<UpdatePaymentIntentResponseObject>;
};

export type UpdatePaymentIntentResponseErrorObject = {
  __typename?: 'UpdatePaymentIntentResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdatePaymentIntentResponseObject = {
  __typename?: 'UpdatePaymentIntentResponseObject';
  stripePaymentIntentId: Scalars['String'];
};

export type UpdatePayoutInterval = {
  schedule: PayoutInterval;
};

export type UpdateVolumeThresholdReached = {
  volumeThresholdReached: Scalars['Float'];
};

export type UpsertSessionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  appCodeName: Scalars['String'];
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  defaultSuggestedTip?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormType>;
  id: Scalars['String'];
  isEmbed: Scalars['Boolean'];
  isSuggestedTipsAmount?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  suggestedTips?: InputMaybe<Array<Scalars['Int']>>;
  tipExperimentValue?: InputMaybe<FeatureFlagValue>;
  url: Scalars['String'];
};

export type UserDetailsObject = {
  __typename?: 'UserDetailsObject';
  firstPaymentDate?: Maybe<Scalars['Date']>;
  lastPaymentDate?: Maybe<Scalars['Date']>;
  totalContribution: Scalars['Float'];
};

export type UserListResponseObject = {
  __typename?: 'UserListResponseObject';
  error?: Maybe<UserListResponseObjectErrorObject>;
  items?: Maybe<Array<UserObject>>;
};

export type UserListResponseObjectErrorObject = {
  __typename?: 'UserListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  contact?: Maybe<ContactObject>;
  contacts?: Maybe<Array<ContactObject>>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  details: UserDetailsObject;
  deviceIds?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<DonationObject>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isContactOfAnotherOrganization: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<OrderObject>>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  refreshToken?: Maybe<Scalars['String']>;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  tags?: Maybe<Array<TagObject>>;
};

export type UserObjectBmPrqPqo = {
  __typename?: 'UserObjectBmPrqPQO';
  error?: Maybe<UserObjectBmPrqPqoErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectBmPrqPqoErrorObject = {
  __typename?: 'UserObjectBmPrqPQOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectO51TcPwx = {
  __typename?: 'UserObjectO51TcPwx';
  error?: Maybe<UserObjectO51TcPwxErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectO51TcPwxErrorObject = {
  __typename?: 'UserObjectO51TcPwxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectii9HEbYl = {
  __typename?: 'UserObjectii9HEbYL';
  error?: Maybe<UserObjectii9HEbYlErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectii9HEbYlErrorObject = {
  __typename?: 'UserObjectii9HEbYLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectkh6IWbxH = {
  __typename?: 'UserObjectkh6IWbxH';
  error?: Maybe<UserObjectkh6IWbxHErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectkh6IWbxHErrorObject = {
  __typename?: 'UserObjectkh6IWbxHErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserPermissions {
  Bank = 'Bank',
  Contacts = 'Contacts',
  Donation = 'Donation',
  Receipt = 'Receipt',
  Ticketing = 'Ticketing',
  UserManagement = 'UserManagement'
}

export enum UserRegistrationStatus {
  Registered = 'Registered',
  Unknown = 'Unknown'
}

export type UserResponseObject = {
  __typename?: 'UserResponseObject';
  error?: Maybe<UserResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserResponseObjectErrorObject = {
  __typename?: 'UserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserRoles {
  Donor = 'Donor',
  Member = 'Member',
  Owner = 'Owner'
}

export type UuidInput = {
  id: Scalars['String'];
};

export type VerifyEmailObject = {
  __typename?: 'VerifyEmailObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type VerifyEmailObjectmrUmb6Vg = {
  __typename?: 'VerifyEmailObjectmrUmb6Vg';
  error?: Maybe<VerifyEmailObjectmrUmb6VgErrorObject>;
  object?: Maybe<VerifyEmailObject>;
};

export type VerifyEmailObjectmrUmb6VgErrorObject = {
  __typename?: 'VerifyEmailObjectmrUmb6VgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebAnalyticsItemObject = {
  __typename?: 'WebAnalyticsItemObject';
  connectionTime: Scalars['Float'];
  count: Scalars['Float'];
  dnsTime: Scalars['Float'];
  firstContentfulPaint: Scalars['Float'];
  firstPaint: Scalars['Float'];
  loadEventTime: Scalars['Float'];
  pageLoadTime: Scalars['Float'];
  pageRenderTime: Scalars['Float'];
  requestTime: Scalars['Float'];
  responseTime: Scalars['Float'];
  time: Scalars['String'];
};

export type WebAnalyticsObject = {
  __typename?: 'WebAnalyticsObject';
  data?: Maybe<Array<WebAnalyticsItemObject>>;
};

export type WebAnalyticsResponseObject = {
  __typename?: 'WebAnalyticsResponseObject';
  error?: Maybe<WebAnalyticsResponseObjectErrorObject>;
  object?: Maybe<WebAnalyticsObject>;
};

export type WebAnalyticsResponseObjectErrorObject = {
  __typename?: 'WebAnalyticsResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeListResponseObject = {
  __typename?: 'WebhooksOverTimeListResponseObject';
  error?: Maybe<WebhooksOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<WebhooksOverTimeObject>>;
};

export type WebhooksOverTimeListResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeObject = {
  __typename?: 'WebhooksOverTimeObject';
  failed: Scalars['Float'];
  queued: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type WebhooksOverTimeResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ZeffyImportObject = {
  __typename?: 'ZeffyImportObject';
  id: Scalars['String'];
};
